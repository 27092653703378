<template>
  <div :key="currentKey"
       :style="currentStyles"
       :class="classes"
       ref="rollover-wrapper"
       @click="showSpinner"
       @mousemove="triggerHoverEffect($event)"
       @mouseleave="resetHoverEffect">
    <div class="quick-info d-flex flex-column"
         :style="{backgroundColor: `${backColor}`}">
      <span class="title">{{title}}</span>
      <span class="info">{{subtitle}}</span>
    </div>

    <div :class="`rollover d-none d-lg-block ${this.borderColor}`"
         ref="glare-wrapper">
      <div class="rollover-glare" ref="glare"></div>
    </div>

    <div class="spinner" v-if="isSpinnerVisible">
      <img src="../assets/img/rolling_64.svg" alt="spinner">
    </div>
  </div>
</template>

<script>
  import AOS from 'aos';

  export default {
    name: "LazyBackground",
    props: {
      title: String,
      subtitle: String,
      backgroundSrc: String,
      emptyBackgroundSrc: String,
      showCorner: Boolean,
      isNew: String,
      backColor: String,
      borderColor: String,
    },
    computed: {
      currentStyles() {
        let styles = {};
        if (!this.loaded) {
          styles['backgroundImage'] = `url(${this.emptyBackgroundSrc})`;
          styles['background-size'] = `10% !important`
        } else {
          styles['backgroundImage'] = `url(${this.backgroundSrc})`;
        }
        return styles;
      }
    },
    data() {
      return {
        currentKey: 1,
        loaded: false,
        classes: {
          corner: false,
          wrapper: true,
        },
        isSpinnerVisible: false,
      }
    },
    mounted() {
      AOS.refresh();
      let img = new Image();
      img.src = this.backgroundSrc;
      const vm = this;
      img.onload = () => {
        vm.loaded = true;
        if (this.isNew === 'yes') {
          this.classes.corner = true;
        }
        this.generateNewKey();
      }
    },
    methods: {
      showSpinner() {
        this.isSpinnerVisible = true;
        setTimeout(() => {
          this.isSpinnerVisible = false;
        }, 500);
      },
      triggerHoverEffect(e) {
        if (getComputedStyle(this.$refs['glare-wrapper']).display === 'block') {
          const maxDegree = 1.53;
          const maxGlareRotation = 180;
          const element = this.$refs['rollover-wrapper'];
          const glareElement = this.$refs['glare'];
          const halfWidth = element.clientWidth / 2;
          const halfHeight = element.clientHeight / 2;
          const xAxis = e.offsetY - halfHeight;
          const yAxis = e.offsetX - halfWidth;
          const yAxisPercentage = yAxis / halfWidth
          const xAxisPercentage = xAxis / halfHeight
          let yDegree = maxDegree * yAxisPercentage * -1;
          let xDegree = maxDegree * xAxisPercentage;

          if (xDegree > 1.53) xDegree = 1.53;
          if (xDegree < -1.53) xDegree = -1.53;
          if (yDegree > 1.53) yDegree = 1.53;
          if (yDegree < -1.53) yDegree = -1.53;

          let glareRotation = yAxisPercentage * maxGlareRotation;

          // element.style.perspective = '300px'
          // element.style.transform = `perspective(300px) rotateX(${xDegree}deg) rotateY(${yDegree}deg)`;
          glareElement.style.transform = `translate(-50%, -50%) rotate(${glareRotation}deg) scale(2)`;
          glareElement.style.opacity = '0.10'
        }
      },
      resetHoverEffect() {
        this.$refs['rollover-wrapper'].style.transform = ``;
        this.$refs['glare'].style.transform = 'translate(-50%, -50%) rotate(180deg) scale(2.1)';
        this.$refs['glare'].style.opacity = '0';
      },
      generateNewKey() {
        this.currentKey = Math.floor(Math.random() * Math.floor(1000))
      }
    }
  }
</script>

<style scoped>
  .wrapper {
    transition: all 0.1s;
    overflow: hidden;
  }

  .rollover {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    /*pointer-events: none;*/
    overflow: hidden;
    border: 1px solid transparent;
  }

  .rollover-glare {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(180deg) scale(2);
    opacity: 0;
    /*transform-origin: 0% 0% 0px;*/
    transition: all 0.1s;
  }

  .rollover.black-border:hover {
    background-color: rgba(0, 0, 0, 0.3);
    border-color: #000;
  }

  .rollover.white-border:hover {
    background-color: rgba(0, 0, 0, 0.3);
    border-color: #fff;
  }

  .corner::before {
    content: 'NEW';
    font-size: 0.7rem;
    position: absolute;
    text-align: center;
    transform: rotate(-45deg);
    background-color: #fff;
    z-index: 5;
    width: 60px;
    height: 60px;
    top: -30px;
    left: -30px;
    padding-left: 5px;
    padding-top: 45px;
  }

  .quick-info {
    /*background-color: #2b7da5;*/
    background-color: #000;
    color: #fff;
    text-align: right;
    padding: 2px 5px 2px 5px;
    bottom: 5px;
    right: 1px;
    position: absolute;
  }

  .quick-info .title {
    font-size: 14px;
    padding: 0;
    margin: 0;
    line-height: 20px;
    font-weight: 400;
  }

  .quick-info .info {
    font-size: 10px;
    padding: 0;
    margin: 0;
    /*white-space: nowrap;*/
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
    font-weight: 400;
  }
  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>