<template>
  <div id="not-found-container"
       class="d-flex flex-column justify-content-center align-items-center">
    <div>
      <h1>Sorry we couldn't find the page you were looking for!</h1>
    </div>
    <div>
      <h4>
        Click here
        <router-link :to="{name: 'page', params: {category: 'home'}}">here</router-link> to go back home!
      </h4>
    </div>
  </div>

</template>

<script>
  export default {
    name: "NotFound"
  }
</script>

<style scoped>
  #not-found-container {
    width: 100%;
    height: 90vh;
    color: #fff;
  }
</style>