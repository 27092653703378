<template>
  <b-row no-gutters class="w-100 align-content-start">
    <!-- Skeleton loaders -->
    <template v-if="this.products.length === 0 && !$store.state.search && $store.state.storeCategory !== 'exhibit'">
      <b-col cols="12" class="store-column-skeleton-loader">
        <img src="../assets/img/skeleton.png"
             alt="Skeleton loader">
      </b-col>
      <b-col cols="12" class="store-column-skeleton-loader">
        <img src="../assets/img/skeleton.png"
             alt="Skeleton loader">
      </b-col>
      <b-col cols="12" class="store-column-skeleton-loader">
        <img src="../assets/img/skeleton.png"
             alt="Skeleton loader">
      </b-col>
    </template>
    <template v-else-if="this.side === 'right' && $store.state.storeCategory === 'exhibit'">
      <div></div>
    </template>
    <b-col v-else
           :key="product.id"
           data-aos="fade-in"
           v-for="product in this.products"
           @click="showDetails(product)"
           :class="`store-item mb-2 position-relative ${product.tags.includes('half-size') ? 'half-size' : ''}`"
           :data-category-handle="product.category"
           :cols="product.tags.includes('half-size') ? 6 : 12">
      <lazy-background class="thumbnail-cover"
                       :title="product.title"
                       :subtitle="`${$store.getters.getProductsByTag(product.tags).length} print${$store.getters.getProductsByTag(product.tags).length > 1 ? 's' : ''}`"
                       :background-src="product.image"
                       :empty-background-src="loadingIcon"
                       :show-corner="false"
                       back-color="#000"
                       border-color="black-border"
      />
    </b-col>
    <div v-if="$store.state.search && $store.getters.products('left').length === 0 && $store.getters.products('right').length === 0"
         class="w-100 mt-5">
      <h4 class="text-center text-black-50">
        No search results,
        <br>
        <a href="#" @click.prevent="clearSearchFn">back to browsing</a>
      </h4>
    </div>
  </b-row>

</template>

<script>
  import LazyBackground from "./LazyBackground";

  export default {
    name: "Store",
    components: {LazyBackground},
    data() {
      return {
        loadingIcon: require('../assets/img/rolling_64.svg'),
      }
    },
    props: {
      products: Array,
      openStoreModal: Function,
      clearSearchFn: Function,
      side: String,
    },
    created() {

    },
    methods: {
      showDetails(product) {
        this.openStoreModal(product);
      },
    }
  }
</script>

<style scoped>
  .store-item {
    height: 370px;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    opacity: 1;
    transition: all 0.3s;
    /*overflow: hidden;*/
    /*border: 1px solid transparent;*/
  }

  .store-item.half-size {
    flex: 0 0 49%;
    max-width: 49%;
    margin-left: 2%;
  }

  .store-item.half-size:nth-child(odd) {
    margin-left: 0;
  }

  .store-item:hover {
    /*opacity: 1;*/
    /*border-color: #969696;*/
  }

  .thumbnail-cover {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  #store-detail-view {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #352020;
    overflow-y: auto;
  }

  #store-detail-view .top-bar-detail {
    height: 60px;
    background-image: url("../assets/img/bg_with_light.png");
  }

  #store-detail-view .close-detail-view {
    width: 50px;
    cursor: pointer;
  }

  #store-detail-view .close-detail-view img {
    width: 36px;
    height: 45px;
  }

  #store-detail-view .close-detail-view:hover {
    background-color: #4a6c7b;
  }

  #store-detail-view .detail-content .description {
    font-size: 1rem;
    font-family: "Proxima Nova Rg", Montserrat, "Trebuchet MS", Arial, sans-serif;
    color: #fff;
    max-width: 70%;
  }

  #store-detail-view .products-wrapper {
    max-width: 80%;
  }

  #store-detail-view .products-wrapper button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 8px 0;
    width: 160px;
    background-color: #2b7da5;
    font-size: 0.9rem;
    border-radius: 5px;
    border: none;
    color: #fff;
  }

  #store-detail-view .products-wrapper button:hover {
    background-color: #2ea6e7;
  }
</style>