import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex);

export function getCategoryLongName(category) {
  switch (category) {
    case 'about':
      return 'About';
    case 'contact':
      return 'Contact';
    case 'home':
      return 'Home';
    case 'web':
      return 'Web & Apps';
    case 'design':
      return 'Design & Branding';
    case 'photography':
      return 'Photography & Digital Art';
    case 'film':
      return 'Film & Video';
    case 'blog':
      return 'News & Blog';
    case 'ai':
      return 'Artificial Intelligence';
    case 'vr':
      return 'Virtual Reality';
    case 'store':
      return 'Store';
  }
  return null
}

export default new Vuex.Store({
  state: {
    category: 'home',
    storeCategory: '',
    search: '',
    entriesToken: '203d48fb1cc34c9c51f6a9168911d37f',
    imagesToken: '70d10f4c77329107fe7f87c4c4468a5d',
    registryToken: '5fc21e15ce6bb916ae21ff30ffd8135e',
    allEntries: {},
    allPress: {},
    menu: {},
    preloadCenterMenu: false,
    isCenterMenuVisible: false,
    metaTags: {
      title: 'Xchematic',
      description: 'Arts & Technology',
      image: require('../assets/img/xsweblogo-07.png'),
      url: window.location.href
    },
    storeBackground: '#d0d0d0',
    isStoreVisible: false, // determine if the store is visible
    keepStoreStyles: false, // used to change about and contact styles
    storeData: {},
    rawStoreData: [],
    cartItems: {},
    nonCategoryTags: ['main', 'left', 'right'],
    isMobileMenuOpen: false,
  },
  getters: {
    menuTitle: state => {
      if (state.isStoreVisible && state.storeData) {
        return 'Art Store';
      }
      return getCategoryLongName(state.category);
    },
    storeMenuTitle: state => {
      let menuItem;
      if (state.storeCategory && state.storeData.menu) {
        menuItem = state.storeData.menu.find(item => {
          return item.handle === state.storeCategory;
        })
      }
      if (menuItem) {
        return menuItem.title;
      }
      return 'Art Store'
    },
    menuItems: state => {
      return state.menu;
    },
    press: state => {
      let selectedPress = [];
      for (let [url, data] of Object.entries(state.allPress)) {
        url = url.substring(15);
        selectedPress.push({
          url,
          ...data
        });
      }
      selectedPress.sort((a, b) => {
        return Number(a.order) - Number(b.order)
      });
      return selectedPress;
    },
    cartSize: state => {
      let size = 0;
      Object.entries(state.cartItems).map(item => {
        size += item[1].quantity ? item[1].quantity : 1;
      });
      return size;
    },
    getCartItem: state => (id) => {
      return Object.entries(state.cartItems).find(item => {
        return item[1].variantId === id;
      })
    },
    getCartTotal: state => {
      let total = 0;
      Object.entries(state.cartItems).map(item => {
        total += Number(item[1].price) * Number(item[1].quantity);
      })
      return total;
    },
    pageBackground: state => {
      if (['about', 'contact'].includes(state.category) && state.keepStoreStyles) {
        return '#fff';
      }
      if (state.category === 'about') {
        return `no-repeat center / cover url(${require('../assets/img/about_bg.jpg')})`;
      } else if (state.category === 'contact') {
        return `no-repeat center / cover url(${require('../assets/img/contact_bg.jpg')})`;
      } else {
        return '';
      }
    },
    getEntryByKey: (state) => (key) => {
      for (let [url, data] of Object.entries(state.allEntries)) {
        if (url === key) {
          url = url.substring(5);
          return {
            url,
            ...data,
          }
        }
      }
    },
    featuredEntries: (state) => (side) => {
      let entries = [];
      if (state.search) {
        return entries;
      }
      for (let [url, data] of Object.entries(state.allEntries)) {
        url = url.substring(5);
        if (state.category === 'home') {
          if (data.show_in_home === 'yes'
            && data.home_side === side
            && data.feature_in_home === 'yes') {
            entries.push({
              url,
              ...data
            });
          }
        } else {
          const matchesSecondaryCategory = data.secondary_category
            ? data.secondary_category.includes(state.category)
            : false;
          if (data.category === state.category
            && data.category_side === side
            && data.feature_in_category === 'yes') {
            entries.push({
              url,
              ...data
            });
          } else if (matchesSecondaryCategory
            && data.secondary_category_side
            && data.secondary_category_side === side
          ) {
            entries.push({
              url,
              ...data
            })
          }
        }
      }
      entries.sort((a, b) => {
        return Number(a.menu_item_order) - Number(b.menu_item_order)
      });
      return entries;
    },
    entries: (state) => (side) => {
      let entries = [];
      for (let [url, data] of Object.entries(state.allEntries)) {
        url = url.substring(5);
        if (state.search) {
          if (data.title.toLowerCase().includes(state.search.toLowerCase())) {
            entries.push({
              url,
              ...data
            })
          }
          continue;
        }
        if (state.category === 'home') {
          if (data.show_in_home === 'yes'
            && data.home_side === side
            && data.feature_in_home === 'no') {
            entries.push({
              url,
              ...data
            })
          }
        } else {
          const matchesSecondaryCategory = data.secondary_category
            ? data.secondary_category.includes(state.category)
            : false;
          if ((data.category === state.category || matchesSecondaryCategory)
            && data.category_side === side
            && data.feature_in_category === 'no') {
            entries.push({
              url,
              ...data
            });
          }
        }
      }
      if (state.search) {
        if (entries.length < 4) {
          if (side === 'left') {
            return entries;
          } else {
            return []
          }
        }
        if (entries.length > 4) {
          if (side === 'left') {
            return entries.slice(0, Math.round(entries.length / 2));
          } else {
            return entries.slice(Math.round(entries.length / 2));
          }
        }

      }
      entries.sort((a, b) => {
        return Number(a.menu_item_order) - Number(b.menu_item_order)
      });
      return entries;
    },
    products: (state) => (side) => {
      let products = [];
      const allProducts = [];
      let iteratedProducts = [];
      state.rawStoreData.map(collectionNode => {
        // if (collectionNode.node.handle === state.storeCategory || state.search) {
        collectionNode.node.products.edges.map(productNode => {
          if (!iteratedProducts.includes(productNode.node.id)
            && productNode.node.tags.includes(side)
            && productNode.node.tags.includes('main')
          ) {
            if (state.search) {
              if (productNode.node.title.toLowerCase().includes(state.search.toLowerCase())) {
                products.push({
                  id: productNode.node.id,
                  title: productNode.node.title,
                  handle: productNode.node.handle,
                  description: productNode.node.description,
                  tags: productNode.node.tags,
                  variants: productNode.node.variants.edges,
                  image: productNode.node.images.edges[0].node.url,
                  category: collectionNode.node.handle,
                });
              }
            } else {
              const order = productNode.node.tags.find(el => {
                return el.startsWith('product-order');
              });
              products.push({
                id: productNode.node.id,
                title: productNode.node.title,
                handle: productNode.node.handle,
                description: productNode.node.description,
                tags: productNode.node.tags,
                variants: productNode.node.variants.edges,
                image: productNode.node.images.edges[0].node.url,
                order: order ?? '',
                category: collectionNode.node.handle,
              })
            }
            iteratedProducts.push(productNode.node.id);
          }
        });

        if (!state.search) {
          products.sort((a, b) => {
            let aOrder = Number(a.order.replace('product-order-', ''))
            let bOrder = Number(b.order.replace('product-order-', ''))
            return bOrder - aOrder;
          });
        }

        allProducts.push(...products);
        products = [];

        // }
      });

      return allProducts;
    },
    mobileProducts: (state) => {
      let products = [];
      const allProducts = [];
      let iteratedProducts = [];
      state.rawStoreData.map(collectionNode => {

        collectionNode.node.products.edges.map(productNode => {
          if (!iteratedProducts.includes(productNode.node.id)
            && productNode.node.tags.includes('main')) {
            if (state.search) {
              if (productNode.node.title.toLowerCase().includes(state.search.toLowerCase())) {
                products.push({
                  id: productNode.node.id,
                  title: productNode.node.title,
                  handle: productNode.node.handle,
                  description: productNode.node.description,
                  tags: productNode.node.tags,
                  variants: productNode.node.variants.edges,
                  image: productNode.node.images.edges[0].node.url,
                  category: collectionNode.node.handle,
                });
              }
            } else {
              const order = productNode.node.tags.find(el => {
                return el.startsWith('product-order');
              });
              products.push({
                id: productNode.node.id,
                title: productNode.node.title,
                handle: productNode.node.handle,
                description: productNode.node.description,
                tags: productNode.node.tags,
                variants: productNode.node.variants.edges,
                image: productNode.node.images.edges[0].node.url,
                order: order ?? '',
                category: collectionNode.node.handle,
              })
            }
            iteratedProducts.push(productNode.node.id);
          }
        });

        if (!state.search) {
          products.sort((a, b) => {
            let aOrder = Number(a.order.replace('product-order-', ''))
            let bOrder = Number(b.order.replace('product-order-', ''))
            return bOrder - aOrder;
          });
        }

        allProducts.push(...products);
        products = [];

      });

      return allProducts;
    },
    getVariantsByTag: (state) => (tags) => {
      const selectedVariants = [];
      let filteredTags = tags.filter(element => {
        return !state.nonCategoryTags.includes(element)
      });
      filteredTags = filteredTags.filter(element => {
        return !element.startsWith('product-order')
      });
      filteredTags = filteredTags.filter(element => {
        return !element.startsWith('product-variant-order')
      });
      state.storeData.products.map(node => {
        if (filteredTags.every(val => node.tags.includes(val))) {
          node.variants.map(variantNode => {
            selectedVariants.push(variantNode.node)
          })
        }
      });
      return selectedVariants;
    },
    getProductByHandle: (state) => (handle) => {
      return state.storeData.products.find(node => {
        return node.handle === handle;
      })
    },
    getProductsByTag: (state) => (tags) => {
      const selectedProducts = [];
      if (!tags) {
        return selectedProducts;
      }
      let filteredTags = tags.filter(element => {
        return !state.nonCategoryTags.includes(element)
      });
      filteredTags = filteredTags.filter(element => {
        return !element.startsWith('product-order')
      });
      filteredTags = filteredTags.filter(element => {
        return !element.startsWith('product-variant-order')
      });
      state.storeData.products.map(node => {
        if (filteredTags.every(val => node.tags.includes(val))) {
          const order = node.tags.find(el => {
            return el.startsWith('product-variant-order');
          });
          selectedProducts.push({
            ...node,
            order: order ?? ''
          });
        }
      });
      selectedProducts.sort((a, b) => {
        let aOrder = Number(a.order.replace('product-variant-order-', ''))
        let bOrder = Number(b.order.replace('product-variant-order-', ''))
        return bOrder - aOrder;
      })
      return selectedProducts;
    }
  },
  mutations: {
    setMobileMenuVisibility(state, visible) {
      state.isMobileMenuOpen = visible;
    },
    updateEntries(state, newEntries) {
      state.allEntries = newEntries;
    },
    updateMenu(state, menuData) {
      state.menu = menuData;
    },
    updatePress(state, newPress) {
      state.allPress = newPress;
    },
    changeCategory(state, newCategory) {
      state.category = newCategory;
    },
    changeStoreCategory(state, newCategory) {
      state.storeCategory = newCategory;
    },
    updateMetaTages(state, metaObject) {
      state.metaTags.title = metaObject.title ? `${metaObject.title}` : 'Xchematic v3';
      state.metaTags.description = metaObject.description;
      state.metaTags.image = metaObject.image ?? state.metaTags.image;
      state.metaTags.url = window.location.href;
    },
    updateSearch(state, searchQuery) {
      state.search = searchQuery;
    },
    setPreloadCenterMenu(state, value) {
      state.preloadCenterMenu = value;
    },
    goToStore(state) {
      state.isStoreVisible = true;
    },
    goToMainSite(state) {
      state.isStoreVisible = false;
    },
    setKeepStoreStyles(state, value) {
      state.keepStoreStyles = value
    },
    addToCart(state, item) {
      if (item.id in state.cartItems) {
        Vue.set(state.cartItems, item.id, {
          variantId: item.id,
          quantity: state.cartItems[item.id].quantity + 1,
          ...item,
        })
      } else {
        Vue.set(state.cartItems, item.id, {
          variantId: item.id,
          quantity: 1,
          ...item,
        })
      }
    },
    removeFromCart(state, id) {
      if (id in state.cartItems) {
        Vue.delete(state.cartItems, id);
      }
    },
    clearCart(state) {
      state.cartItems = {};
    },
    setCartItemQuantity(state, payload) {
      if (payload.id in state.cartItems) {
        Vue.set(state.cartItems[payload.id], 'quantity', payload.newQuantity);
      }
    },
    setStoreData(state, storeData) {
      let data = {
        menu: [],
        products: []
      };
      let iteratedProducts = [];
      storeData.map(categoryNode => {
        data.menu.push({
          id: categoryNode.node.id,
          title: categoryNode.node.title,
          handle: categoryNode.node.handle,
          description: categoryNode.node.description,
        });
        categoryNode.node.products.edges.map(productNode => {
          if (!iteratedProducts.includes(productNode.node.id)) {
            data.products.push({
              id: productNode.node.id,
              title: productNode.node.title,
              categoryHandle: categoryNode.node.handle,
              handle: productNode.node.handle,
              description: productNode.node.description,
              tags: productNode.node.tags,
              variants: productNode.node.variants.edges,
              image: productNode.node.images.edges[0].node.url
            });
            iteratedProducts.push(productNode.node.id);
          }
        });
      });
      if (data.menu.length > 0 && !state.storeCategory) {
        state.storeCategory = data.menu[0].handle;
      }
      state.storeData = data;
      state.rawStoreData = storeData;
    }
  },
  actions: {
    loadEntries(context) {
      return new Promise(((resolve, reject) => {
        axios.get('/api/delivery/entries', {
          params: {
            id: 'home',
            token: context.state.entriesToken,
            'args[]': ''
          }
        }).then(function (res) {
          context.commit('updateEntries', res.data);
          resolve();
        }).catch(function () {
          reject();
        })
      }))
    },
    loadMenu(context) {
      return new Promise(((resolve, reject) => {
        axios.get('/api/delivery/entries', {
          params: {
            id: 'menu-items',
            token: context.state.entriesToken
          }
        }).then(res => {
          context.commit('updateMenu', res.data.items);
          resolve();
        }).catch(() => {
          reject();
        })
      }))
    },
    loadStoreProducts(context) {
      return new Promise(((resolve, reject) => {
        axios.get('https://xchematic.com/store-products')
          .then(res => {
            context.commit('setStoreData', res.data);
            resolve();
          }).catch(() => {
            reject();
          })
      }))
    },
    loadPress(context) {
      return new Promise(((resolve, reject) => {
        axios.get('/api/delivery/entries', {
          params: {
            id: 'cm-press-links',
            token: context.state.entriesToken,
            'args[]': '',
          }
        }).then(function (res) {
          context.commit('updatePress', res.data);
          resolve();
        }).catch(function () {
          reject();
        })
      }))
    },
  },
  modules: {}
})
