<template>
  <div class="d-flex flex-column cart-add align-items-center">
    <div class="d-flex align-items-center justify-content-center mb-1">

      <i class="fa fa-caret-left mr-2"
         style="cursor:pointer;"
         @click="decrementCartCount"></i>
      <b-form-input class="text-center"
                    v-on:keydown="onlyNumber($event)"
                    v-model.number="quantity"
                    style="width: 160px;"></b-form-input>
      <i class="fa fa-caret-right ml-2"
         style="cursor:pointer;"
         @click="incrementCartCount"></i>
    </div>
    <button class="text-uppercase"
            @click.prevent="addToCart">
      Add to Cart
    </button>
  </div>
</template>

<script>
  export default {
    name: "CartAddButton",
    data() {
      return {
        quantity: 1
      }
    },
    props: {
      variantId: String,
      title: String,
      imageURL: String,
      price: String,
      clickAction: Function,
    },
    methods: {
      addToCart() {
        this.$store.commit('addToCart', {
          id: this.variantId,
          title: this.title,
          imageURL: this.imageURL,
          price: this.price,
        });
        this.$store.commit('setCartItemQuantity', {
          id: this.variantId,
          newQuantity: this.quantity ? this.quantity : 1,
        });
        this.quantity = 1;
        this.clickAction();
      },
      incrementCartCount() {
        this.quantity += 1;
      },
      decrementCartCount() {
        if (this.quantity > 1) {
          this.quantity -= 1;
        }
      },
      onlyNumber(e) {
        if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(e.key)) {
          e.preventDefault();
        }
      },
    }
  }
</script>

<style scoped>
  button {
    font-family: "Proxima Nova Rg", Montserrat, "Trebuchet MS", Arial, sans-serif;
    padding: 8px 0;
    width: 160px;
    background-color: #2b7da5;
    font-size: 0.9rem;
    border-radius: 5px;
    border: none;
    color: #fff;
  }

  button:hover {
    background-color: #000;
  }
</style>