import Vue from 'vue'
import axios from 'axios'
import AOS from 'aos';
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import vueHeadful from 'vue-headful';
import App from './App.vue'
import router from './router'
import store from './store'
import VueApollo from 'vue-apollo';
import {apolloClient} from './apollo-config';

AOS.init();

Vue.config.productionTip = false;

Vue.use(VueApollo)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component('vue-headful', vueHeadful);

axios.defaults.baseURL = 'https://api.xchematic.com';

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App)
}).$mount('#app');
