import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Page from "../views/Page";
import NotFound from "../views/NotFound";
import store from '../store'
// import About from '../views/About'

Vue.use(VueRouter);

const Preload = () => import('../views/Page.vue');

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/portfolio/:category',
    name: 'page',
    component: Preload
  },
  {
    path: '/artstore/:category?/:product?',
    name: 'store',
    component: Page
  },
  {
    path: '/store',
    redirect: {
      name: 'store',
      params: {
        category: 'world-tour'
      }
    }
  },
  {
    path: '/about',
    name: 'about',
    component: Page
  },
  {
    path: '/contact',
    name: 'contact',
    component: Page
  },
  {
    path: '/:project',
    name: 'project',
    component: Page
  },
  {
    path: '/error',
    name: 'not-found',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === 'store') {
    store.commit('goToStore');
  } else {
    store.commit('goToMainSite');
  }
  let promises = [];
  if(Object.entries(store.state.menu).length === 0) {
    promises.push(store.dispatch('loadMenu'));
  }
  if(Object.entries(store.state.allEntries).length === 0) {
    promises.push(store.dispatch('loadEntries'));
  }
  if (Object.entries(store.state.storeData).length === 0) {
    promises.push(store.dispatch('loadStoreProducts'));
  }
  if (Object.entries(store.state.allPress).length === 0) {
    promises.push(store.dispatch('loadPress'));
  }
  Promise.all(promises).then(() => {
    if (to.name === 'project' && !store.state.allEntries[`home/${to.params.project}`]) {
      next({name: 'not-found', replace: true})
      return;
    }
    if ((to.name === 'page' && !store.state.menu[to.params.category])) {
      if (to.params.category !== 'home') {
        next({name: 'not-found', replace: true})
        return;
      }
    }
  })
  next();
})

export default router
