<template>
  <div id="app"
       :style="appBackground">
    <vue-headful
        :title="$store.state.metaTags.title"
        :description="$store.state.metaTags.description"
        :image="$store.state.metaTags.image"
        :url="$store.state.metaTags.url"/>

    <div id="mobile-menu"
         :class="`d-block d-xl-none pb-1 ${$store.state.isStoreVisible || $store.state.keepStoreStyles ? 'store-style' : ''}`">
      <div class="d-flex flex-column align-items-center position-relative">

        <div id="upper-menu" class="w-100">

          <router-link v-if="$store.state.isStoreVisible || $store.state.keepStoreStyles"
                       :class="`d-block d-md-none text-uppercase mobile-menu-store-link store-style faster ${$store.state.isMobileMenuOpen ? 'animated fadeOut' : 'animated fadeIn'}`"
                       :to="{name: 'page', params: {category: 'home'}}">
            BACK TO MAIN SITE
          </router-link>
          <router-link v-else
                       style="color: #fff !important;"
                       :class="`d-block d-md-none text-uppercase mobile-menu-store-link faster ${$store.state.isMobileMenuOpen ? 'animated fadeOut' : 'animated fadeIn'}`"
                       :to="{name: 'store', params: {category: 'world-tour'}}">
            GO TO ART STORE
          </router-link>

          <div id="menu-logo" class="d-flex justify-content-center">
            <router-link :to="{name: 'index'}"
                         class="d-flex align-items-center">
              <img v-if="!$store.state.isStoreVisible && !$store.state.keepStoreStyles"
                   src="./assets/img/xsweblogo-08.png"
                   alt="Xchematic">
              <img v-else
                   src="./assets/img/xs-logo-flat-logo-only.png"
                   alt="Xchematic">
              <p class="menu-title">xchematic</p>
            </router-link>
          </div>

          <div id="open-menu"
               @click="toggleMobileMenu"
               class="d-block d-md-none ">
            <i class="fa fa-bars"></i>
          </div>
        </div>

        <div id="menu-items"
             class="d-none d-md-flex flex-md-wrap justify-content-center align-items-stretch align-items-md-center align-content-md-center flex-column flex-md-row menu-animation pb-2">

          <div class="center-menu-spiel p-0 pr-md-3 pb-2">
            <h4 v-if="$store.state.isStoreVisible || $store.state.keepStoreStyles"
                class="m-0" style="color: #000">
              Works by <a href="https://www.carlosmats.com" target="_blank" class="p-0">Carlos Mats</a>,
              <br>
              framed prints
              <br>
              shipping worldwide.
            </h4>
            <h4 v-else class="m-0" style="color: #aaa">
              Works by <a href="https://www.carlosmats.com" target="_blank" class="p-0">Carlos Mats</a>
              <br>
              and the Xchematic Team.
            </h4>
          </div>

          <router-link @click.native="closeMobileMenu"
                       v-if="!$store.state.isStoreVisible && !$store.state.keepStoreStyles"
                       :to="{name: 'index'}">
            COVER
          </router-link>
          <router-link @click.native="closeMobileMenu"
                       v-if="!$store.state.isStoreVisible && !$store.state.keepStoreStyles"
                       :to="{name: 'page', params: { category: 'home'}}">
            HOME
          </router-link>

          <!-- Main Site Menu items -->
          <template v-if="!$store.state.isStoreVisible && !$store.state.keepStoreStyles">
            <router-link v-for="(item, slug) in $store.state.menu"
                         :key="slug"
                         :to="{name: 'page', params: {category: slug}}"
                         class="text-uppercase"
                         @click.native="closeMobileMenu">
              <span class="d-inline d-sm-none">{{ item.long_name }}</span>
              <span class="d-none d-sm-inline">{{ item.short_name }}</span>
            </router-link>
          </template>

          <!-- Store menu items -->
          <template v-if="$store.state.isStoreVisible || $store.state.keepStoreStyles">
            <router-link v-for="item in $store.state.storeData.menu"
                         :key="item.id"
                         :to="{name: 'store', params: {category: item.handle}}"
                         @click.native="scrollToPosition(item.handle, item.id)"
                         :class="getNavigationClasses(item.id)">
              {{ item.title }}
            </router-link>
            <router-link
                :to="{name: 'store', params: {category: 'exhibit'}}"
                class="text-uppercase"
                @click.native="closeMobileMenu">
              Exhibit
            </router-link>
          </template>

          <router-link @click.native="closeMobileMenu"
                       :to="{name: 'about', params: { category: 'about'}}"
                       class="text-uppercase">
            ABOUT
          </router-link>
          <router-link @click.native="closeMobileMenu"
                       :to="{name: 'contact', params: { category: 'contact'}}"
                       class="text-uppercase">
            CONTACT
          </router-link>

          <template>
            <router-link v-if="$store.state.isStoreVisible || $store.state.keepStoreStyles"
                         class="text-uppercase mobile-menu-store-link mt-2 mt-md-0 align-self-center store-style"
                         @click.native="closeMobileMenu"
                         :to="{name: 'page', params: {category: 'home'}}">
              BACK TO MAIN SITE
            </router-link>
            <router-link v-else-if="$store.state.storeData.menu"
                         class="text-uppercase mobile-menu-store-link mt-2 mt-md-0 align-self-center"
                         @click.native="closeMobileMenu"
                         :to="{name: 'store', params: {category: 'world-tour'}}">
              GO TO ART STORE
            </router-link>
          </template>

        </div>

      </div>
    </div>

    <div v-if="$route.name !== 'not-found'"
         ref="collapsible-menu" id="collapsible-menu"
         :data-store-visible="$store.state.isStoreVisible || $store.state.keepStoreStyles"
         class="d-none faster col-2 p-0"
         @mouseleave="closeMenu">
      <router-link :to="{name: 'index'}" style="background: none">
        <img v-if="!$store.state.isStoreVisible && !$store.state.keepStoreStyles"
             src="./assets/img/xsweblogo-07.png"
             class="px-3 pt-3 px-lg-5 pt-lg-5"
             alt="Xchematic"/>
        <img v-else
             src="./assets/img/xs-logo-flat.png"
             class="px-3 pt-3 px-lg-5 pt-lg-5"
             alt="Xchematic"/>
      </router-link>

      <h4 class="mt-2">{{ this.centerMenuTitle }}</h4>

      <div class="center-menu-spiel pt-2">
        <h4 class="m-0" v-if="$store.state.isStoreVisible || $store.state.keepStoreStyles">
          Works by <a href="https://carlosmats.com" target="_blank">Carlos Mats</a>,
          <br>
          framed prints
          <br>
          shipping worldwide.
        </h4>
        <h4 v-else class="m-0" style="color: #aaa">
          Works by <a href="https://carlosmats.com" target="_blank">Carlos Mats</a>
          <br>
          and the Xchematic Team.
        </h4>
      </div>

      <div id="portfolio-menu"
           class="d-flex flex-column mt-4">
        <router-link v-if="$store.state.isStoreVisible || $store.state.keepStoreStyles"
                     :to="{name: 'page', params: {category: 'home'}}"
                     class="store-link text-uppercase text-white align-self-center mb-2">
          BACK TO MAIN SITE
        </router-link>

        <router-link v-else
                     class="store-link-main-site text-uppercase align-self-center mb-2"
                     :to="{name: 'store', params: {category: 'world-tour'}}">
          GO TO ART STORE
        </router-link>
        <router-link v-if="!$store.state.isStoreVisible && !$store.state.keepStoreStyles"
                     :to="{name: 'index'}" class="text-uppercase">
          COVER
        </router-link>
        <router-link v-if="!$store.state.isStoreVisible && !$store.state.keepStoreStyles"
                     :to="{name: 'page', params: { category: 'home'}}">
          HOME
        </router-link>

        <!-- Main site menu items -->
        <template v-if="!$store.state.isStoreVisible && !$store.state.keepStoreStyles">
          <router-link
              :key="slug"
              v-for="(item, slug) in $store.getters.menuItems"
              :to="{name: 'page', params: {category: slug}}"
              class="text-uppercase">
            {{ item.long_name }}
          </router-link>
        </template>

        <!-- Store menu items -->
        <template v-if="$store.state.isStoreVisible || $store.state.keepStoreStyles">
          <router-link
              :key="item.id"
              v-for="item in $store.state.storeData.menu"
              :to="{name: 'store', params: {category: item.handle}}"
              @click.native="scrollToPosition(item.handle, item.id)"
              :class="getNavigationClasses(item.id)">
            {{ item.title }}
          </router-link>
          <router-link
              :to="{name: 'store', params: {category: 'exhibit'}}"
              class="text-uppercase">
            Exhibit
          </router-link>
        </template>

        <!-- Static shared menu items -->
        <router-link :to="{name: 'about', params: { category: 'about'}}"
                     class="text-uppercase">
          ABOUT
        </router-link>
        <router-link :to="{name: 'contact', params: { category: 'contact'}}"
                     class="text-uppercase">
          CONTACT
        </router-link>
      </div>

      <div class="center-menu-spiel pt-2">
        <h4 class="m-0" v-if="$store.state.isStoreVisible || $store.state.keepStoreStyles">
          $2 from each piece
          <br>
          goes to COVID relief
          <br>
          in Honduras and Guatemala
        </h4>
      </div>

      <div id="store-nav" class="mt-4 d-flex flex-column align-items-center">
        <div class="mt-5 d-flex flex-column justify-content-center align-items-center">
          <a href="#"
             :class="`cart mb-2 ${!$store.state.isStoreVisible && !$store.state.keepStoreStyles && $store.getters.cartSize === 0 ? 'invisible' : ''}`"
             v-b-popover.hover.top="$store.getters.cartSize > 0 ? 'Click to view your shopping cart' : 'You have no items in your cart'"
             @click.prevent="openShoppingCart">
            <img v-if="($store.getters.cartSize > 0) && ($store.state.isStoreVisible || $store.state.keepStoreStyles)"
                 src="./assets/img/xs-store-cart-black.png" alt="Shopping Cart">
            <img
                v-else-if="$store.getters.cartSize === 0 && ($store.state.isStoreVisible || $store.state.keepStoreStyles)"
                src="./assets/img/xs-store-cart-gray.png" alt="Shopping Cart">
            <img
                v-else-if="$store.getters.cartSize > 0 && !$store.state.isStoreVisible && !$store.state.keepStoreStyles"
                src="./assets/img/xs-store-cart-white.png" alt="Shopping Cart">
            <span v-if="$store.getters.cartSize > 0" class="badge badge-danger">
              {{ $store.getters.cartSize }}
            </span>
          </a>

          <p v-if="checkoutRedirectMessage" class="my-2 font-weight-bold text-secondary">
            {{ checkoutRedirectMessage }}
          </p>

          <button
              :class="`my-1 store-link text-uppercase text-white ${!$store.state.isStoreVisible && !$store.state.keepStoreStyles ? 'invisible' : ''}`"
              :disabled="$store.getters.cartSize === 0 || this.processingCheckout"
              @click="checkout($event)">
            CHECK-OUT
          </button>

        </div>
      </div>

    </div>

    <transition
        :enter-active-class="this.enterCoverAnimationClasses"
        :leave-active-class="this.leaveCoverAnimationClasses">
      <router-view
          :open-store-modal="this.openStoreModal"
          :store-modal="$refs['store-product-modal']"
          :open-shopping-cart-modal="this.openShoppingCart"
      ></router-view>
    </transition>

    <b-modal ref="checkout-modal" centered id="checkout-modal" size="lg">
      <template v-slot:modal-header="{ close }">
        <div class="w-100 position-relative">
          <h3 class="text-center">Shopping Cart</h3>
          <div
              class="close-modal align-self-stretch d-flex align-items-center justify-content-center shopping-cart-close"
              @click="close()">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </template>
      <div v-if="$store.getters.cartSize === 0"
           class="d-flex justify-content-center align-items-center"
           style="height: 200px">
        <h5 class="text-secondary m-0">No Items in the Cart</h5>
      </div>
      <b-container fluid class="pb-3" v-if="$store.getters.cartSize > 0">
        <b-row no-gutters
               :key="item.id"
               v-for="item in this.$store.state.cartItems">
          <b-col cols="6" md="2" class="d-flex align-items-center">
            <img :src="item.imageURL" alt="product image" class="w-100">
          </b-col>
          <b-col cols="6" md="3" class="d-flex align-items-center justify-content-center">
            <h6>{{ item.title }}</h6>
          </b-col>
          <b-col cols="4" md="3" class="d-flex align-items-center justify-content-center">
            <div class="d-flex align-items-center justify-content-center">
              <i class="fa fa-caret-left mr-2"
                 style="cursor:pointer;"
                 @click="decrementCartCount(item.id, item.quantity)"></i>
              <b-form-input v-model.number="item.quantity"
                            class="text-center"
                            v-on:keydown="onlyNumber($event)"
                            style="width: 70px;"></b-form-input>
              <i class="fa fa-caret-right ml-2"
                 style="cursor:pointer;"
                 @click="incrementCartCount(item.id, item.quantity)"></i>
            </div>
          </b-col>
          <b-col cols="4" md="2" class="d-flex flex-column align-items-center justify-content-center">
            <h5 class="m-0 text-center">Item Subtotal</h5>
            <h6 class="m-0 text-center" style="color: #2b7da5;">${{ item.price * item.quantity }}</h6>
          </b-col>
          <b-col cols="4" md="2" class="d-flex align-items-center justify-content-center">
            <b-button variant="danger" @click="removeFromCart(item.id)">Remove</b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
        <div v-if="$store.getters.cartSize > 0"
             class="d-flex justify-content-end">
          <h5>Subtotal: <span style="color: #2b7da5">${{ $store.getters.getCartTotal }}</span></h5>
        </div>
      </b-container>
      <template v-slot:modal-footer>
        <div class="d-flex w-100 justify-content-center">
          <b-button v-if="$store.getters.cartSize > 0"
                    class="store-link"
                    @click="checkout($event)"
                    :disabled="processingCheckout">
            CHECK-OUT
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Modal store items -->

    <b-modal id="store-modal"
             scrollable
             hide-footer
             static
             ref="store-product-modal" slide-enter size="xl"
             header-class="remove-modal-header-padding">
      <template v-slot:modal-header="{ close }">
        <div id="project-modal-header"
             class="d-flex align-items-center w-100 justify-content-end position-relative">
          <div v-if="storeModalShown"
               @click="openShoppingCart"
               id="modal-store-cart"
               tabindex="0"
               v-b-popover.hover="$store.getters.cartSize > 0 ? 'Click to view your shopping cart' : 'You have no items in your cart'">
            <img
                class="w-100"
                src="./assets/img/xs-store-cart-gray.png">
            <span v-if="$store.getters.cartSize > 0"
                  class="badge badge-danger">
              {{ $store.getters.cartSize }}
            </span>
          </div>
          <div
              class="close-modal align-self-stretch d-flex align-items-center justify-content-center"
              @click="close()">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </template>
      <template v-slot:default>
        <div id="inner-store-modal-content">
          <div id="store-product-cover-image">
            <img :src="selectedStoreProduct.image" alt="Cover Image" class="parallax-image"
                 ref="parallax-image">
          </div>
          <div id="content-box">
            <h1>{{ selectedStoreProduct.title }}</h1>
            <p>
              {{ selectedStoreProduct.description }}
            </p>
          </div>
          <div v-if="selectedStoreProduct"
               class="pt-5">
            <store-product-row
                :key="p.id"
                v-for="p in $store.getters.getProductsByTag(selectedStoreProduct.tags)"
                :product="p"/>
          </div>
          <div id="custom-modal-footer"
               class="d-flex flex-column flex-sm-row align-items-center justify-content-center">
            <a :href="modalStoreShareLinks.facbeook" target="_blank"
               class="share-button ml-0 ml-md-2 mb-2">
              <img src="./assets/img/share_f.png" alt="Share on Facebook">
              <p>SHARE ON FACEBOOK</p>
            </a>
            <a :href="modalStoreShareLinks.twitter" target="_blank"
               class="share-button ml-0 ml-md-2 mb-2">
              <img src="./assets/img/share_t.png" alt="Share on Twitter">
              <p>SHARE ON TWITTER</p>
            </a>
            <a href="#" @click.prevent="scrollModalTop"
               class="share-button ml-0 ml-md-2 mb-2">
              <i class="fa fa-chevron-up" style="color: #fff" title="Facebook"></i>
              <p>BACK TO TOP</p>
            </a>
          </div>

        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'aos/dist/aos.css';
import gql from 'graphql-tag';
import StoreProductRow from "./components/StoreProductRow";
import AOS from "aos";
import $ from 'jquery';

export default {
  name: "App",
  components: {StoreProductRow},
  data() {
    return {
      enterCoverAnimationClasses: '',
      leaveCoverAnimationClasses: '',
      collections: '',
      processingCheckout: false,
      checkoutRedirectMessage: '',
      modalStoreShareLinks: {
        facbeook: '',
        twitter: ''
      },
      selectedStoreProduct: {},
      selectedProductVariant: null,
      allProductVariants: null,
      storeModalShown: false,
      disableCheckoutButton: false,
      aosInterval: 0,
      navigationMenuClasses: {},
    }
  },
  computed: {
    centerMenuTitle() {
      if (this.$store.state.isStoreVisible || this.$store.state.keepStoreStyles) {
        return 'Art Store';
      }
      return 'Portfolio';
    },
    appBackground() {
      if (this.$store.state.isStoreVisible || this.$store.state.keepStoreStyles) {
        return {
          backgroundColor: '#fff',
        }
      }
      return {
        backgroundImage: 'linear-gradient(#2d424c, #000)',
      }
    },
  },
  mounted() {
    function preventScroll() {
      document.getElementById('store-modal').scrollTop = 0;
    }

    // get scroll positions for each category
    // this.scrollToPosition(this.$route.params.category)
    window.addEventListener('scroll', this.manageStoreScroll);
    this.$refs['store-product-modal'].$on('show', () => {
      document.getElementById('store-modal').addEventListener('scroll', preventScroll);
      setTimeout(() => {
        document.getElementById('store-modal').removeEventListener('scroll', preventScroll);
      }, 1000);
    });
    this.$refs['store-product-modal'].$on('shown', () => {
      let storeProductModal = document.querySelector('#store-modal .modal-body');
      let parallaxCover = storeProductModal.querySelector('#store-product-cover-image .parallax-image');
      setTimeout(() => {
        storeProductModal.addEventListener('scroll', function () {
          let newTranslate = storeProductModal.scrollTop * 0.2;
          parallaxCover.style.transform = `translateY(${newTranslate}px) scale(1.15)`;
        })
      }, 900);
      const rows = document.querySelectorAll("#inner-store-modal-content .store-modal-html-content");
      rows.forEach((currentEl, currentIndex) => {
        if (currentIndex === 0) {
          return;
        }
        currentEl.classList.remove('aos-animate');
      });

      AOS.init();
      this.aosInterval = setInterval(() => {
        AOS.refresh(true);
      }, 300);

    })
    this.$refs['store-product-modal'].$on('hide', () => {
      let parallaxCover = document.querySelector('#store-modal .modal-body')
          .querySelector('#store-product-cover-image .parallax-image');
      parallaxCover.style.transform = `translateY(0) scale(1.15)`;
      this.hideStoreModal();
      clearInterval(this.aosInterval);
    });
  },
  watch: {
    '$route'(to) {
      if (to.path !== '/') {
        this.enterCoverAnimationClasses = '';
        this.leaveCoverAnimationClasses = 'animated coverExitAnimation';
      } else {
        this.leaveCoverAnimationClasses = '';
        this.enterCoverAnimationClasses = 'animated slideInDown';
      }
    }
  },
  methods: {
    getNavigationClasses(itemId) {
      let finalClasses = 'text-uppercase';
      if (itemId in this.navigationMenuClasses) {
        finalClasses += ` ${this.navigationMenuClasses[itemId]}`;
      }
      return finalClasses;
    },
    setNavigationClasses(itemId, classString) {
      this.closeMobileMenu();
      this.clearNavigationClasses();
      if (itemId && classString) {
        this.navigationMenuClasses[itemId] = classString;
      }
    },
    clearNavigationClasses() {
      this.navigationMenuClasses = {};
    },
    scrollToPosition(handle, itemId) {
      this.setNavigationClasses(itemId, 'destination-link-active')
      this.closeMobileMenu();
      const offset = document.querySelector(`[data-category-handle="${handle}"]`).offsetTop;
      $("html").animate({
        scrollTop: offset,
      }, 2500, 'swing', () => {
        this.clearNavigationClasses();
      });
    },
    manageStoreScroll() {
      if (this.$store.state.isStoreVisible && this.$store.state.storeData) {
        for (let i = 0; i < this.$store.state.storeData.menu.length; i++) {
          let from,
              to;
          // const previousItem = this.$store.state.storeData[i].length - 1 === 0
          //     ? null
          //     : this.$store.state.storeData.menu[i - 1];
          const menuItem = this.$store.state.storeData.menu[i];
          const nextItem = this.$store.state.storeData.menu.length - 1 === i
              ? null
              : this.$store.state.storeData.menu[i + 1];
          if (nextItem) {
            to = document.querySelector(`[data-category-handle="${nextItem.handle}"]`).offsetTop - 8;
          } else {
            to = document.body.scrollHeight;
          }
          from = document.querySelector(`[data-category-handle="${menuItem.handle}"]`).offsetTop - 8;
          if (window.scrollY >= from
              && window.scrollY < to
              && menuItem.handle !== this.$store.state.storeCategory) {
            this.$router.push({
              name: 'store',
              params: {
                category: menuItem.handle,
              }
            });
            break;
          }
        }
        // this.$store.state.storeData.menu.map((menuItem) => {
        // });
      }

    },
    onlyNumber(e) {
      if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(e.key)) {
        e.preventDefault();
      }
    },
    prepStore() {
      if (this.$route.name === 'store') {
        if (!this.$route.params.category) {
          this.$router.replace({
            name: 'store',
            params: {
              category: this.$store.state.storeData.menu[0].handle
            }
          })
        }
        if (this.$route.params.product) { // a product is selected, open modal
          const selectedProduct = this.$store.getters.getProductByHandle(this.$route.params.product);
          if (selectedProduct) {
            this.openStoreModal(selectedProduct);
          }
        }
      }
    },
    SmoothVerticalScrolling(e, time, where) {
      let eTop = e.getBoundingClientRect().top;
      let eAmt = eTop / 100;
      let curTime = 0;
      while (curTime <= time) {
        window.setTimeout(this.SVS_B, curTime, eAmt, where);
        curTime += time / 100;
      }
    },
    SVS_B(eAmt, where) {
      if (where === "center" || where === "")
        window.scrollBy(0, eAmt / 2);
      if (where === "top")
        window.scrollBy(0, eAmt);
    },
    openStoreModal(productData) {
      this.$router.push({
        name: 'store',
        params: {
          category: this.$route.params.category,
          product: productData.handle
        }
      })
      this.selectedStoreProduct = productData;
      this.selectedProductVariant = this.selectedStoreProduct.variants[0].node;
      this.allProductVariants = this.$store.getters.getVariantsByTag(this.selectedStoreProduct.tags);
      this.storeModalShown = true;
      this.modalStoreShareLinks.facbeook = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
      this.modalStoreShareLinks.twitter = `https://www.twitter.com/share?url=${window.location.href}&text=${document.title}`;
      this.$refs['store-product-modal'].show();
    },
    hideStoreModal() {
      this.storeModalShown = false;
      this.$router.push({
        name: 'store',
        params: {
          category: this.$route.params.category
        }
      })
    },
    openShoppingCart() {
      if (this.$store.getters.cartSize > 0) {
        this.$refs['checkout-modal'].show();
      }
    },
    removeFromCart(cartItemId) {
      this.$store.commit('removeFromCart', cartItemId);
    },
    incrementCartCount(id, currentQuantity) {
      this.$store.commit('setCartItemQuantity', {
        id: id,
        newQuantity: currentQuantity + 1
      })
    },
    decrementCartCount(id, currentQuantity) {
      if (currentQuantity > 1) {
        this.$store.commit('setCartItemQuantity', {
          id: id,
          newQuantity: currentQuantity - 1
        })
      }
    },
    checkout(e) {
      if (this.$store.getters.cartSize > 0) { // do checkout
        this.processingCheckout = true;
        e.target.innerHTML = 'Processing...';
        this.$apollo.mutate({
          mutation: gql`mutation checkoutCreate($input: CheckoutCreateInput!) {
              checkoutCreate(input: $input) {
                checkout {
                  id
                  webUrl
                }
              }
            }`,
          variables: {
            input: {
              lineItems: Object.entries(this.$store.state.cartItems).map(el => {
                return {
                  variantId: el[1].variantId,
                  quantity: el[1].quantity
                }
              })
            }
          }
        }).then((data) => { // redirect user
          this.checkoutRedirectMessage = 'Redirecting to checkout page...';
          this.processingCheckout = false;
          window.location.href = data.data.checkoutCreate.checkout.webUrl;
        }).catch(() => {
          this.processingCheckout = false;
          e.target.innerHTML = 'CHECK-OUT';
          alert('There was an error with the process, please try again later.')
        });
      } else {
        alert('No items in the Shopping Cart');
      }
    },
    scrollModalTop() {
      // this.SmoothVerticalScrolling(document.querySelector('#store-modal .modal-body'), 1500, "top");
      document.querySelector('#store-modal .modal-body').scrollTo({top: 0, behavior: 'smooth'});
    },
    toggleMobileMenu() {
      let menu = document.getElementById('menu-items');

      function handleEnterAnimationEnd() {
        menu.classList.remove('menu-animation', 'menuSlideDown');
        menu.removeEventListener('animationend', handleEnterAnimationEnd);
      }

      function handleLeaveAnimationEnd() {
        menu.classList.replace('d-flex', 'd-none');
        menu.classList.remove('menu-animation', 'menuSlideUp');
        menu.removeEventListener('animationend', handleLeaveAnimationEnd);
      }

      if (menu.classList.contains('d-none')) {
        this.$store.commit('setMobileMenuVisibility', true);
        menu.classList.add('menu-animation', 'menuSlideDown');
        menu.classList.replace('d-none', 'd-flex');
        menu.addEventListener('animationend', handleEnterAnimationEnd);
      } else {
        this.$store.commit('setMobileMenuVisibility', false);
        menu.classList.add('menu-animation', 'menuSlideUp');
        menu.addEventListener('animationend', handleLeaveAnimationEnd);
      }
    },
    closeMobileMenu() {
      let menu = document.getElementById('menu-items');
      let hamburger = document.getElementById('open-menu');

      function handleLeaveAnimationEnd() {
        menu.classList.replace('d-flex', 'd-none');
        menu.classList.remove('menu-animation', 'menuSlideUp');
        menu.removeEventListener('animationend', handleLeaveAnimationEnd);
      }

      this.$store.commit('setMobileMenuVisibility', false);
      if (menu.classList.contains('d-flex') && hamburger.classList.contains('d-block')) {
        menu.classList.add('menu-animation', 'menuSlideUp');
        menu.addEventListener('animationend', handleLeaveAnimationEnd);
      }
    },
    closeMenu() {
      const menu = document.querySelector('#collapsible-menu');

      function handleAnimationEnd() {
        menu.classList.remove('animated', 'XslideOutUp');
        menu.classList.replace('d-block', 'd-none');
        menu.removeEventListener('animationend', handleAnimationEnd);
      }

      if (this.$route.name === 'index') {
        this.$store.state.isCenterMenuVisible = false;
        menu.classList.add('animated', 'XslideOutUp');
        menu.addEventListener('animationend', handleAnimationEnd);
      }
    },
  }
}
</script>

<style>
html {
  /*scroll-behavior: smooth;*/
}

html, body {
  font-family: "Proxima Nova Rg", Montserrat, "Trebuchet MS", Arial, sans-serif;
  width: 100%;
  /*min-height: 100%;*/
}

body {
  /*background-image: linear-gradient(#2d424c, #000);*/
  overflow-y: scroll;
}

.font-futura {
  font-family: Futura, josefin sans, Trebuchet MS, Arial, sans-serif;
}

.font-kozuka {
  font-family: 'Kozuka Gothic Pr6N EL', 'PT Sans', 'Trebuchet MS', Arial, sans-serif;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.page-title {
  color: #aaa;
}

.page-title small {
  font-size: 0.8rem;
}

.store-style .page-title {
  color: #000;
}

.store-style.page-title {
  color: #000;
}

@media (max-width: 1200px) {
  #main-container {
    margin-top: 20px;
  }
}

.column-skeleton-loader {
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.store-column-skeleton-loader {
  height: 370px;
  display: flex;
  background-color: #f9f9f9;
  align-items: center;
  justify-content: center;
}

.store-column-skeleton-loader img {
  height: 25%;
}

.menu-animation {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes menuSlideDown {
  from {
    max-height: 0;
    margin-top: 0;
  }
  to {
    max-height: 600px;
    margin-top: 85px;
  }
}

@keyframes menuSlideDown {
  from {
    max-height: 0;
    margin-top: 0;
  }
  to {
    max-height: 600px;
    margin-top: 85px;
  }
}

.menuSlideDown {
  -webkit-animation-name: menuSlideDown;
  animation-name: menuSlideDown;
}

@-webkit-keyframes menuSlideUp {
  from {
    max-height: 600px;
    margin-top: 85px;
  }
  to {
    max-height: 0;
    margin-top: 0;
  }
}

@keyframes menuSlideUp {
  from {
    max-height: 600px;
    margin-top: 85px;
  }
  to {
    max-height: 0;
    margin-top: 0;
  }
}

.menuSlideUp {
  -webkit-animation-name: menuSlideUp;
  animation-name: menuSlideUp;
}

[data-store-visible] .center-menu-spiel a {
  text-decoration-color: #000;
  color: #000;
}

.center-menu-spiel a {
  color: rgb(170, 170, 170);
}

.center-menu-spiel a {
  text-decoration-color: rgb(170, 170, 170);
}

.center-menu-spiel h4 {
  font-size: 0.6rem !important;
  /*color: #626262 !important;*/
  color: #2b7da5;
  font-style: italic;
  text-align: center;
}

@media (min-width: 1024px) {
  .center-menu-spiel {
    position: absolute;
    left: 10px;
  }

  #mobile-menu .mobile-menu-store-link {
    position: absolute;
    right: 10px;
  }
}

@media (min-width: 1200px) {
  .center-menu-spiel {
    position: unset;
    left: 0;
  }

  .center-menu-spiel h4 {
    font-size: 0.8rem !important;
  }
}

#collapsible-menu {
  background-color: #000;
  font-family: tw cen mt, trebuchet ms, Arial, sans-serif;;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  z-index: 15;
  font-size: 0.9rem;
  transition: all 1s;
  overflow: auto;
  animation-delay: 0.2s;
}

#collapsible-menu[data-store-visible] {
  background-color: #d0d0d0;
}

#collapsible-menu img {
  width: 92%;
}

#collapsible-menu h4 {
  font-family: proxima nova rg, Montserrat, trebuchet ms, Arial, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
}

#collapsible-menu[data-store-visible] h4 {
  color: #000;
}

#collapsible-menu #portfolio-menu a.router-link-exact-active {
  color: #337ab7;
  background-color: #fff;
}

#collapsible-menu[data-store-visible] #portfolio-menu a.router-link-exact-active {
  color: #fff !important;
  background-color: #000 !important;
}

#collapsible-menu[data-store-visible] #portfolio-menu a.destination-link-active {
  color: #fff;
  background-color: #393737;
}

#collapsible-menu #portfolio-menu a {
  text-decoration: none;
  color: #aaa;
  padding: 6px 15px;
}

#collapsible-menu #portfolio-menu a:hover {
  color: #337ab7;
  background-color: #fff;
}

#collapsible-menu[data-store-visible] #portfolio-menu a:hover {
  color: #fff;
  background-color: #000;
}

#collapsible-menu[data-store-visible] #portfolio-menu a {
  color: #626262;
}

#collapsible-menu #store-nav {
  font-family: proxima nova rg, Montserrat, trebuchet ms, Arial, sans-serif;
  position: absolute;
  bottom: 200px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

@media (max-height: 1000px) {
  #collapsible-menu #store-nav {
    bottom: 100px;
  }
}

@media (max-height: 850px) {
  #collapsible-menu #store-nav {
    bottom: 10px;
  }
}


#store-nav .cart {
  width: 50px;
  position: relative;
}

#store-nav .cart img {
  opacity: 0.8;
}

#store-nav .cart img:hover {
  opacity: 1;
}

#store-nav .cart span {
  font-size: 14px;
  position: absolute;
  width: 21px;
  height: 21px;
  top: -8px;
  right: -8px;
  border-radius: 50%;
}

.store-link-main-site {
  padding: 8px 0;
  width: 150px;
  background-color: #2b7da5;
  font-size: 0.9rem;
  border-radius: 5px;
  border: none;
  color: #fff !important;
}

.store-link-main-site:hover {
  text-decoration: none;
  background-color: #2b7da5;
  color: #2b7da5 !important;
}

.store-link {
  padding: 8px 0;
  width: 160px;
  background-color: #2b7da5;
  font-size: 0.9rem;
  border-radius: 5px;
  border: none;
}

.store-link:hover {
  text-decoration: none;
  background-color: #000;
}

.store-link:disabled {
  background-color: #93bfdd;
}

@media (max-width: 1200px) {
  #hidden-center-menu {
    display: none !important;
  }

  #collapsible-menu {
    display: none !important;
  }
}

/* Custom animations */
@-webkit-keyframes XheartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes XheartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.XheartBeat {
  -webkit-animation-name: XheartBeat;
  animation-name: XheartBeat;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@-webkit-keyframes XslideInDown {
  from {
    -webkit-transform: translate3d(-50%, -100%, 0);
    transform: translate3d(-50%, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes XslideInDown {
  from {
    -webkit-transform: translate3d(-50%, -100%, 0);
    transform: translate3d(-50%, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

.XslideInDown {
  -webkit-animation-name: XslideInDown;
  animation-name: XslideInDown;
}

@-webkit-keyframes XslideOutUp {
  from {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-50%, -100%, 0);
    transform: translate3d(-50%, -100%, 0);
  }
}

@keyframes XslideOutUp {
  from {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-50%, -100%, 0);
    transform: translate3d(-50%, -100%, 0);
  }
}

.XslideOutUp {
  -webkit-animation-name: XslideOutUp;
  animation-name: XslideOutUp;
}

#app {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  position: relative;
}

.main-content {
  height: 100%;
}

.main-content.store-style {
  height: 100vh;
}

@media (max-width: 767px) {
  .main-content {
    padding-top: 50px;
  }
}

@media (min-width: 768px) {
  .main-content {
    /*padding-top: 114px;*/
    padding-top: 120px;
  }

  .main-content.store-style {
    padding-top: 98px;
  }
}

@media (min-width: 960px) {
  .main-content {
    /*padding-top: 85px;*/
    padding-top: 98px;
  }

  .main-content.store-style {
    padding-top: 98px;
  }
}

@media (min-width: 1200px) {
  .main-content {
    padding-top: 80px;
  }

  .main-content.store-style {
    padding-top: 80px;
  }

}

#mobile-menu {
  min-height: 50px;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
}

#mobile-menu.store-style {
  background-color: #d0d0d0;
}

#mobile-menu a {
  text-decoration: none;
  color: #aaa;
}

#mobile-menu a:hover {
  color: #fff;
}

#mobile-menu .mobile-menu-store-link {
  padding: 6px 15px;
  font-size: 11px;
  background-color: #fff;
  color: #2b7da5 !important;
  border-radius: 5px
}

#mobile-menu .mobile-menu-store-link.store-style {
  background-color: #2b7da5;
  color: #fff !important;
}

#upper-menu {
  position: relative;
}

#upper-menu .mobile-menu-store-link {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  padding: 4px 6px;
  color: #aaa;
  border: 1px solid #aaa;
  background-color: transparent !important;
}

#upper-menu .mobile-menu-store-link.store-style {
  border: 1px solid #fff;
  color: #2b7da5 !important;
}

#upper-menu .center-menu-spiel {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

#menu-logo {
  margin: 5px 0;
  height: 35px;
}

#menu-logo img {
  width: 34px;
}

#menu-logo p {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}

.store-style #menu-logo p {
  color: #000;
}

#menu-items {
  font-size: 11px;
  margin-top: 85px;
  overflow: hidden;
  width: 100%;
  min-height: 50px;
}

@media (min-width: 767px) {
  #menu-items {
    margin-top: 0;
  }
}

#menu-items a {
  padding: 9px 15px;
}

.store-style #menu-items a {
  color: #626262;
}

#menu-items a.router-link-exact-active {
  color: #337ab7;
  background-color: #fff;
}

.store-style #menu-items a.router-link-exact-active {
  color: #fff;
  background-color: #000;
}

#open-menu {
  color: #fff;
  width: 35px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
  border-radius: 3px;
  padding: 3px;
  background-color: #fff;
  border: 1px solid #fff;
}

#open-menu i {
  color: #000;
}

.store-style #open-menu {
  border-color: #000;
  background-color: #000;
}

.store-style #open-menu i {
  color: #fff;
}

#menu-logo-wrapper {
  background-color: #000;
  padding: 10px 30px;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -60.5px;
  margin-top: 30px;
}

#menu-logo-wrapper img {
  width: 81px;
}

/* Modal Styles, this styles are placed here because dynamic content is being loaded */
#project-modal-header {
  background-image: url("./assets/img/bg_with_light.png");
  height: 40px;
  color: #aaa;
  font-family: kozuka gothic pr6n el, pt sans, trebuchet ms, Arial, sans-serif;
}

#project-modal-header i {
  font-size: 2rem;
}

.close-modal {
  background-color: transparent;
  width: 42px;
  cursor: pointer;
  padding: 5px;
}

.close-modal i {
  font-size: 2rem;
}

.close-modal:hover {
  background-color: #4a6c7b;
}

.shopping-cart-close {
  position: absolute;
  right: 0;
  top: 0;
}

.remove-modal-header-padding {
  padding: 0;
}

#store-product-cover-image {
  min-height: 25vh;
  max-height: 750px;
  overflow: hidden;
}

#store-product-cover-image .parallax-image {
  width: 100%;
  transform: scale(1.15);
  transition: all 0.15s;
  transition-timing-function: ease-out;
}

#content-box {
  background-color: #63a4c5;
  width: 600px;
  min-height: 200px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /*margin: -250px auto 0 auto;*/
  /*margin: auto;*/
  z-index: 10;
  padding: 60px 60px;
  box-shadow: 0 0 40px #333;
}

#content-box a:not(.print-available) {
  color: #fff;
}

#content-box h1 {
  color: #fff;
  font-size: 36px;
  font-family: kozuka gothic pr6n el, pt sans, trebuchet ms, Arial, sans-serif;
}

#content-box h4 {
  color: #fff;
  font-size: 12px;
  font-family: Futura, josefin sans, Trebuchet MS, Arial, sans-serif;
  text-transform: uppercase;
}

#content-box p {
  color: #294155;
  font-size: 16px;
  font-style: italic;
}

#content-box .corner-content {
  position: absolute;
  right: 30px;
  top: 30px;
  color: #000;
  font-size: 12px;
}

#content-box .corner-content p {
  padding: 0;
  margin: 0;
  font-family: Futura, josefin sans, Trebuchet MS, Arial, sans-serif;
  font-size: 12px;
  color: #132a3b;
}

.print-available {
  padding: 10px 15px;
  font-size: 0.9rem;
  background-color: #fff;
  color: #2b7da5;
  border-radius: 5px;
  display: block;
  width: 160px;
  text-align: center;
}

#custom-modal-footer .print-available {
  margin-top: -100px;
}

#content-box .print-available {
  margin: auto;
}

@media (max-width: 576px) {
  #custom-modal-footer .print-available {
    margin-top: 0;
  }
}

.print-available:hover {
  text-decoration: none;
  background-color: #000;
  color: #fff;
}

.store-modal-html-content {
  font-family: kozuka gothic pr6n el, pt sans, trebuchet ms, Arial, sans-serif;
  overflow: hidden;
}

@media (min-width: 768px) {
  .store-modal-html-content {
    margin-top: -60px;
  }
}

.store-modal-html-content .selected-variant-image-wrapper {
  max-height: 800px;
  min-height: 600px;
  position: relative;
}

.store-modal-html-content .selected-variant-image-wrapper .loading-icon {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.store-modal-html-content .selected-variant-image {
  width: 100%;
  /*position: relative;*/
  /*top: 50%;*/
  transform: translateY(-7%) scale(1.05);
}

.store-modal-html-content .selected-variant-image-loader {
  width: 200px;
  height: 200px;
}

@media (max-width: 992px) {
  .store-modal-html-content .selected-variant-image-wrapper {
    min-height: unset;
  }
}

@media (max-width: 1300px) {
  .store-modal-html-content .selected-variant-image {
    transform: scale(1.25);
  }
}

@media (max-width: 480px) {
  .store-modal-html-content .selected-variant-image {
    transform: scale(1.15);
  }
}

.store-modal-html-content .variant-thumbnail {
  cursor: pointer;
  max-width: 180px;
  /*height: 180px;*/
  border: 3px solid transparent;
}

.store-modal-html-content .variant-thumbnail:hover {
  border: 3px solid #7cc1ea;
}

.store-modal-html-content .variant-thumbnail[selected-variant] {
  border: 3px solid #2b7da5;
}

.store-modal-html-content .product-name {
  font-size: 1.9rem;
  color: #3b3b3b;
  text-align: center;
  font-family: 'Kozuka Gothic Pr6N EL', 'PT Sans', 'Trebuchet MS', Arial, sans-serif;
}

.store-modal-html-content .product-price {
  font-size: 1.9rem;
  color: #2b7da5;
  font-family: proxima nova rg, Montserrat, trebuchet ms, Arial, sans-serif;
}

.store-modal-html-content .product-description {
  font-size: 0.9rem;
  margin: 0;
  font-family: proxima nova rg, Montserrat, trebuchet ms, Arial, sans-serif;
}

.store-modal-html-content .product-description a {
  color: inherit;
}

.store-modal-html-content .product-variant-title {
  font-size: 1.1rem;
  font-weight: 700;
  margin: 0;
  font-family: proxima nova rg, Montserrat, trebuchet ms, Arial, sans-serif;
}

#modal-html-content {
  font-family: kozuka gothic pr6n el, pt sans, trebuchet ms, Arial, sans-serif;
  margin-top: -100px;
}

#modal-html-content p {
  font-family: proxima nova rg, Montserrat, trebuchet ms, Arial, sans-serif;
}

#modal-html-content h1 {
  font-size: 36px;
}

#modal-html-content div.image-wrapper {
  min-height: 450px;
  padding: 20px;
  background-color: rgb(251, 251, 251);
  font-family: proxima nova rg, Montserrat, trebuchet ms, Arial, sans-serif;
  background-image: url("./assets/img/skeleton.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100px;
}

#modal-html-content img {
  width: 100% !important;
}

#modal-html-content div.store-item-wrapper {
  font-family: proxima nova rg, Montserrat, trebuchet ms, Arial, sans-serif;
  padding: 0 20px;
}

#modal-html-content div.store-item-wrapper img {
  max-width: 700px !important;
}

.modal-body {
  padding: 0;
}

.modal-footer {
  background-color: #060606;
}

#custom-modal-footer {
  min-height: 200px;
  width: 100%;
  margin: 0;
  z-index: 20;
  background-color: #000;
}

.share-button {
  color: #595959;
  display: flex;
  width: 160px;
  height: 50px;
  padding: 0 5px;
  align-content: center;
  align-items: center;
  border: 1px solid #464646;
  border-radius: 5px;
  transition: all .5s;
}

.share-button:hover {
  text-decoration: none;
  color: #428caf;
  border-color: #428caf;
}

.share-button img,
.share-button i {
  margin-right: 10px;
  width: 9px;
}

.share-button p {
  margin: 0;
  border-left: 1px solid #428caf;
  padding-left: 0;
  text-align: center;
  font-size: 14px;
  height: 50px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

}

#cover-modal .modal-header {
  background-color: transparent;
  border: none;
  font-family: 'Kozuka Gothic Pr6N EL', 'PT Sans', 'Trebuchet MS', Arial, sans-serif;
  color: #fff;
}

#cover-modal .modal-header .close-cover-modal {
  font-size: 2rem;
  cursor: pointer;
}

#cover-modal .modal-content {
  background-color: transparent;
}

#cover-modal .modal-footer {
  background-color: transparent;
  border: none;
  color: #fff;
}

#footer-video-modal .modal-content {
  background-color: transparent;
}

#footer-video-modal .modal-header {
  background-color: transparent;
  font-family: "Proxima Nova Rg", Montserrat, "Trebuchet MS", Arial, sans-serif;
  color: #fff;
  border: none;
  padding: 0;
}

#footer-video-modal .modal-header h2 {
  font-size: 30px;
}

#footer-video-modal .modal-footer {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: none;
}

#footer-video-modal .modal-body {
  background-color: #000;
}

.modal-xl {
  max-width: 1500px;
  width: 90%;
}

.modal-dialog {
  margin: auto;
}

.modal-backdrop {
  opacity: 0.9;
}

.carousel-item .carousel-caption {
  position: absolute;
  top: 40% !important;
  bottom: unset !important;
  left: unset;
  right: 50px;
  transform: translateY(-50%) !important;
}


#currency-dropdown button {
  background-color: #121212;
}

#currency-dropdown {
  width: 15% !important;
}

.budget-input {
  width: 85% !important;
  background-color: #121212 !important;
}

@media (max-width: 768px) {
  #content-box {
    width: 100%;
    margin-left: 0;
    left: 0;
    top: 25%;
    transform: translate(0, 0);
  }

  #modal-html-content {
    margin: 0;
  }

  #modal-html-content div.image-wrapper {
    padding: 10px;
    min-height: 150px;
  }

  #modal-html-content div.image-wrapper > iframe {
    height: auto !important;
  }
}

#cover-modal .modal-lg {
  max-width: 900px;
}

[slide-enter] .modal.fade .modal-dialog {
  transition: all 0.8s ease-out !important;
  transform: translateY(1000px) !important;
  -webkit-transform: translateY(1000px) !important;
}

[slide-enter] .modal.show .modal-dialog {
  transform: translateY(10px) !important;
  -webkit-transform: translateY(10px) !important;
}

#main-modal .modal-header {
  z-index: 100;
}

#store-modal .modal-header {
  z-index: 100;
}

#inner-store-modal-content {
  /*height: 500px;*/
}

/*** Modal Styles **/
#modal-html-content .project-modal-dark {
  background-color: #000;
  padding: 40px 100px;
}

#modal-html-content .project-head-block {
  font-family: kozuka gothic pr6n el, pt sans, trebuchet ms, Arial, sans-serif;
  font-size: 36px;
  color: #63a4c5;
}

#modal-html-content .project-text-block {
  font-family: proxima nova rg, Montserrat, trebuchet ms, Arial, sans-serif;
  column-count: 2;
  column-gap: 80px;
  font-size: 16px;
  color: #fff;
}

#modal-html-content .project-text-block p {
  margin: 10px 0;
}

#modal-html-content .project-text-block a {
  color: #fff;
  text-decoration: underline;
}

#modal-html-content .project-text-block ul,
#modal-html-content .project-text-block ol {
  margin-bottom: 10px;
  margin-top: 0;
  padding-left: 35px;
}

/**** Styles imported from previous project  *****/

.proj_details {
  color: #000;
  background: #e4e4e4;
  z-index: 2;
}

.proj_trans {
  position: relative;
  width: 100%;
  z-index: 3;
}

.proj_light {
  position: relative;
  width: 100%;
  background: #EEEEEE;
  box-shadow: 0 -10px 25px -10px rgba(0, 0, 0, 0.5) inset;
  background-image: -ms-linear-gradient(bottom left, #ffffff 0%, #CCCCCC 100%);
  background-image: -moz-linear-gradient(bottom left, #ffffff 0%, #CCCCCC 100%);
  background-image: -o-linear-gradient(bottom left, #ffffff 0%, #CCCCCC 100%);
  background-image: -webkit-gradient(linear, left bottom, right top, color-stop(0, #ffffff), color-stop(100, #CCCCCC));
  background-image: -webkit-linear-gradient(bottom left, #ffffff 0%, #CCCCCC 100%);
  background-image: linear-gradient(to top right, #ffffff 0%, #CCCCCC 100%);
}

.proj_dark {
  width: 100%;
  background: #000;
  padding: 40px 100px;
}

@media only screen and (max-width: 992px) {
  .proj_dark {
    padding: 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .proj_dark {
    padding: 30px;
  }
}

.proj_light a,
.proj_dark a {
  text-decoration: underline;
}

.proj_quote {
  width: 100%;
  padding: 50px;
  background: #ffffff;
  text-align: center;
  box-shadow: 0 10px 25px -10px rgba(0, 0, 0, 0.5) inset;
  font-family: proxima nova rg, Montserrat, trebuchet ms, Arial, sans-serif;
}

.proj_main_img {
  width: 100%;
  margin-top: 75px;
  padding: 25px;
  background: #e4e4e4;
  border-bottom: 7px solid #efefef;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .proj_main_img {
    margin-top: 65px;
    padding: 10px;
  }
}

.proj_main_img img {
  width: 100%;
}

.proj_desc {
  width: 600px;
  margin: -150px auto 0;
  background: #63a4c5;
  padding: 60px;
  box-shadow: 0px 0px 40px #333;
}

@media only screen and (max-width: 767px) {
  .proj_desc {
    margin: 0 auto;
  }
}

.proj_info {
  float: right;
  margin-top: -30px;
  margin-right: -30px;
  color: #132a3b;
  font-family: Futura, "Josefin Sans", Trebuchet MS, Arial, sans-serif;
  font-size: 12px;
  text-align: left;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .proj_desc {
    top: -50px;
    left: 0;
    width: 100%;
    padding: 20px;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .proj_info {
    margin-top: -10px;
    margin-right: -5px;
  }
}

.proj_desc h1 {
  font-family: "Kozuka Gothic Pr6N EL", "PT Sans", "Trebuchet MS", Arial, sans-serif;
  font-size: 36px;
}

@media only screen and (max-width: 767px) {
  .proj_desc h1 {
    font-size: 30px;
  }
}

.proj_desc h5 {
  font-family: Futura, "Josefin Sans", Trebuchet MS, Arial, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
}

.proj_desc h1,
.proj_desc h5 {
  float: none;
  clear: both;
  color: #FFF;
}

.proj_text_block {
  width: 800px;
  margin: 0 auto;
  padding: 40px;
  color: #000;
  font-size: 16px;
  word-break: break-word;
  text-align: left;
}

@media only screen and (max-width: 992px) {
  .proj_text_block {
    width: auto;
  }
}

@media only screen and (max-width: 767px) {
  .proj_text_block {
    padding: 30px 20px;
  }
}

.proj_text_block p {
  color: #000;
  font-size: 16px;
  font-family: proxima nova rg, Montserrat, trebuchet ms, Arial, sans-serif;
}

.proj_desc .proj_text_block {
  width: 100%;
  padding: 0;
  color: #294155;
  font-size: 12px;
}

.proj_desc .proj_text_block p {
  color: #294155;
  font-size: 16px;
  font-style: italic;
}

.proj_head_block {
  text-align: center;
  position: relative;
  color: #63a4c5;
  font-family: "Kozuka Gothic Pr6N EL", "PT Sans", "Trebuchet MS", Arial, sans-serif;
  font-size: 36px;
}

@media only screen and (max-width: 767px) {
  .proj_head_block {
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.proj_head_block .h_link {
  position: absolute;
  left: 0;
  top: 10px;
  font-size: 18px;
  color: #777;
  margin-left: -30px;
  padding-right: 20px;
  opacity: 0;
}

.proj_head_block:hover .h_link {
  opacity: 0.8;
}

.proj_head_block .h_link:hover {
  opacity: 1;
}

.proj_dark .proj_text_block {
  width: 100%;
  padding: 0;
  color: #FFF;
}

@media only screen and (min-width: 768px) {
  .proj_dark .proj_text_block {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 100px;
    -moz-column-gap: 100px;
    column-gap: 100px;
  }
}

.proj_dark .proj_text_block p {
  color: #FFF;
}

.proj_quote_block {
  width: auto;
  height: auto;
  margin: 0 auto;
  color: #0f638d;
  font-size: 20px;
  font-style: italic;
  border: 0;
  border-top: 1px solid #5691ae;
  border-bottom: 1px solid #aac7d6;
}

@media only screen and (min-width: 1025px) {
  .proj_quote_block {
    width: 800px;
    padding: 40px 100px;
  }
}

.proj_img_block {
  display: block;
  width: 100%;
  height: auto;
  padding: 25px;
  padding-bottom: 15px;
  background: #e4e4e4;
  border-bottom: 7px solid #efefef;
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .proj_img_block {
    padding: 10px;
    padding-bottom: 5px;
  }
}

.proj_img_block img {
  width: 100%;
}

.proj_img_block figcaption {
  width: 60%;
  color: #1c1c1c;
  font-size: 16px;
  text-align: center;
  margin: 0 auto 15px;
}

.proj_video_block {
  background: #e4e4e4;
  padding: 25px 0;
  border-bottom: 7px solid #efefef;
  text-align: center;
}

.proj_video_block iframe {
  margin: 0 auto !important;
}

@media only screen and (max-width: 1024px) {
  .proj_video_block iframe.giphy-embed {
    width: 100%;
    height: 780px;
  }
}

@media only screen and (max-width: 991px) {
  .proj_video_block iframe.giphy-embed {
    height: 630px;
  }
}

@media only screen and (max-width: 767px) {
  .proj_video_block iframe.giphy-embed {
    height: 300px;
  }
}

.proj_table_block {
}

.proj_gallery_block {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  padding: 25px 100px;
  background: #e4e4e4;
}

@media only screen and (max-width: 991px) {
  .proj_video_block {
  }

  .proj_table_block {
  }

  .proj_gallery_block {
    padding: 10px 15px;
  }
}

.proj_img_block figure,
.proj_gallery_block figure {
  width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .carousel-item .carousel-caption {
    right: 0 !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 100%;
  }

  .carousel-item .carousel-caption h1 {
    font-size: 38px !important;
  }
}

@media (max-width: 575px) {
  #content-box {
    padding: 60px 20px;
    text-align: center;
  }

  #content-box h1 {
    text-align: center;
    font-size: 32px;
  }

  #content-box .corner-content {
    right: 0;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
  }
}

/* Store styles */
#checkout-modal .modal-content {
  background-color: transparent;
}

#checkout-modal .modal-header {
  background-color: rgba(0, 0, 0, 0.7);
  font-family: 'Kozuka Gothic Pr6N EL', 'PT Sans', 'Trebuchet MS', Arial, sans-serif;
  color: #fff;
  border: none;

}

#checkout-modal .modal-body {
  background-color: #fff;
  min-height: 150px;
}

#checkout-modal .modal-footer {
  border: none;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-store-cart {
  color: #d0d0d0;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  position: absolute;
  /*z-index: 1500;*/
  cursor: pointer;
  width: 30px;
}

#modal-store-cart span {
  font-size: 12px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: -3px;
  right: -8px;
  border-radius: 50%;
  font-family: "Proxima Nova Rg", Montserrat, "Trebuchet MS", Arial, sans-serif;
}

#store-modal .store-item-wrapper .cart-add button {
  /*position: absolute;*/
  /*bottom: 50px;*/
  /*left: 50%;*/
  /*transform: translateX(-50%);*/
  padding: 8px 0;
  width: 160px;
  background-color: #2b7da5;
  font-size: 0.9rem;
  border-radius: 5px;
  border: none;
  color: #fff;
}

#store-modal .store-item-wrapper .cart-add button:hover {
  background-color: #2ea6e7;
}

.shopping-cart {
  color: #000;
  font-size: 2.2rem !important;
}

</style>
