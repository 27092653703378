<template>
  <div class="footer-wrapper">
    <div class="images-wrapper">

      <div class="element" v-for="(d, index) in elements" :key="index">
        <div v-if="d.isColor" :style="{backgroundColor: d.background}" class="background-wrapper color">
          <img class="element-image" :src="d.image"
               :style="d.additionalStyles">
        </div>

        <div v-else :style="{backgroundImage: `url(${d.background})`}" class="background-wrapper image">
          <div v-if="d.isProject"
               @click="openModal(d.projectURL)"
               :data-url="d.projectURL" class="project-link">
            <img class="element-image" :src="d.image"
                 :style="d.additionalStyles">
          </div>
        </div>
      </div>
    </div>

    <div class="header">
      <h4>{{ this.footerTitle }}</h4>
    </div>
    <div class="indicators">
      <i :key="idx"
         v-for="(item, idx) in elements"
         :class="`fa fa-circle ${idx === 0 ? 'active' : ''}`"
         @click="switchImage(idx)">

      </i>
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs';

export default {
  name: "FooterElement",
  props: {
    footerTitle: String,
    elements: Array,
    openModalFn: Function,
  },
  data() {
    return {
      currentImage: 0
    }
  },
  mounted() {
    let hammer = new Hammer(this.$el);
    hammer.on('swipe', (e) => {
      if (e.deltaX < 0) {
        if (this.currentImage + 1 < this.elements.length) {
          this.switchImage(this.currentImage + 1);
        }
      } else if (e.deltaX > 0) {
        if (this.currentImage - 1 >= 0) {
          this.switchImage(this.currentImage - 1);
        }
      }
    });
    setInterval(() => {
      if ((this.currentImage + 1) >= this.elements.length) {
        this.currentImage = 0;
      } else {
        this.currentImage++;
      }
      this.switchImage(this.currentImage);
    }, 15000); // 15s
  },
  methods: {
    openModal(url) {
      this.openModalFn(url);
    },
    switchImage(index) {
      const indicators = this.$el.querySelectorAll('.indicators i');
      indicators.forEach(el => {
        el.classList.remove('active')
      });
      const wrapper = this.$el.querySelector('.images-wrapper');
      this.currentImage = index;
      let percentage = (index * 100) * -1;
      wrapper.style.transform = `translateX(${percentage}%)`;
      indicators[index].classList.add('active');
    }
  }
}
</script>

<style scoped>
.footer-wrapper {
  position: relative;
  overflow: hidden;
  display: flex;
}

.images-wrapper {
  display: flex;
  transition: all 0.5s;
}

.images-wrapper .element {
  min-width: 100%;
  height: 100%;
}

.images-wrapper .element .background-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.images-wrapper .background-wrapper.color .element-image {
  width: 55%;
  margin-top: 35px;
}

.images-wrapper .background-wrapper.image .element-image {
  margin-top: 40px;
  width: 100%;
  /*height: 120px;*/
  border: 1px solid transparent;
}

.images-wrapper .background-wrapper.image .element-image:hover {
  border-color: #fff;
}

.footer-wrapper .header {
  position: absolute;
  top: 15px;
  left: 30px;
}

.footer-wrapper .header h4 {
  font-family: "Tw Cen MT", "Trebuchet MS", Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}

.indicators {
  position: absolute;
  right: 10px;
  top: 15px;
  width: 60px;
  display: flex;
  justify-content: space-around;
}

.indicators i {
  color: #b3babe;
  margin-right: 4px;
  font-size: 6px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid transparent;
}

.indicators .active {
  border: 1px solid #b3babe;
}

.project-link {
  position: relative;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .footer-wrapper .header h4 {
    font-size: 11px;
  }
}
</style>