<template>
  <div id="index-cover" class="animated fadeIn">

    <div class="d-none d-xl-block" id="logo-wrapper" @mouseenter="openMenu">
      <img src="../assets/img/xsweblogo-08.png" alt="">
    </div>

    <div id="carousel-indicators">
      <i class="fa fa-circle indicator active" @click="changeSlide(0)" data-slide-to="0"></i>
      <i class="fa fa-circle indicator" @click="changeSlide(1)" data-slide-to="1"></i>
    </div>

    <b-carousel
        ref="coverCarousel"
        id="covers-wrapper"
        background="#4a4a4a"
        :interval="0">

      <b-carousel-slide class="cover" :style="{backgroundImage: `url(${cover1})`}">
        <div class="content">
          <h1>20 YEARS</h1>
          <h4>IN ARTS & TECH</h4>
          <p>XCHEMATIC IS AN AWARD-WINNING<br>
            STUDIO SPECIALIZED IN <br>
            ART, DESIGN AND <br>
            TECHNOLOGY.</p>
        </div>
      </b-carousel-slide>

      <b-carousel-slide class="cover" :style="{backgroundImage: `url(${cover2})`}">
        <div class="content text-wrap" style="color: #beb1aa">
          <h1>VAENQUISH</h1>
          <h4>A FILM BY</h4>
          <h4>XCHEMATIC</h4>
          <img src="../assets/img/play-icon.png" class="play-btn" @click="openModal">
        </div>
      </b-carousel-slide>
    </b-carousel>

    <img src="../assets/img/scroll.png"
         @click="this.goToNextPage"
         class="scroll">

    <b-modal id="cover-modal" ref="coverModal" slide-enter size="lg">
      <template v-slot:modal-header="{ close }">
        <div>
          <b-row class="w-100" no-gutters>
            <b-col cols="8" md="6" class="d-flex flex-column">
              <h2>Vaenquish (Short Film)</h2>
              <p class="text-uppercase font-futura" style="font-size: 11px">Xchematic's pièce de résistance</p>
            </b-col>
            <b-col cols="1" class="d-none d-md-block">
              <img src="../assets/img/xsweblogo-08.png" class="w-100">
            </b-col>
            <b-col cols="4" md="5" class="d-flex flex-column text-right cover-header-side-text text-uppercase">
              <span id="cvr_cat">Film &amp; Video, Home</span>
              <span id="cvr_loc">Amsterdam</span>
              <span id="cvr_year">2016</span>
            </b-col>
          </b-row>
          <i class="fa fa-times float-right close-cover-modal"
             @click="close"></i>
        </div>
      </template>
      <iframe width="100%" height="480" frameborder="0" allowfullscreen="" mozallowfullscreen=""
              style="width: 100%; max-height: 350px"
              webkitallowfullscreen=""
              src="https://player.vimeo.com/video/153722619?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;app_id=122963"
              allow="autoplay; fullscreen"
              title="Vaenquish - Short film by Xchematic [Spanish Subs]" data-ready="true"></iframe>
      <template v-slot:modal-footer>
        <b-row class="d-flex flex-column flex-md-row justify-content-between" no-gutters>
          <b-col cols="12" md="3" class="d-flex flex-row flex-md-column align-items-start justify-content-around">
            <a href="https://twitter.com/share?url=https://www.xchematic.com/vaenquish&amp;text=Xchematic - Vaenquish (Short Film)&amp;hashtags=Film & Video,Home"
               target="_blank" class="share-button mb-3">
              <img src="../assets/img/share_f.png" alt="Share on Facebook">
              <p>SHARE ON FACEBOOK</p>
            </a>
            <a href="https://twitter.com/share?url=https://www.xchematic.com/vaenquish&amp;text=Xchematic - Vaenquish (Short Film)&amp;hashtags=Film & Video,Home"
               target="_blank" class="share-button">
              <img src="../assets/img/share_t.png" alt="Share on Twitter">
              <p>SHARE ON TWITTER</p>
            </a>
          </b-col>
          <b-col cols="12" md="6">
            <p class="body-text modal-footer-text">
              The ultimate passion project; went from a daydream to 5 nominations and two
              awards for Best Cinematography &amp; Best Editing, to a Hollywood Screening
              in The Los Angeles Film School. The beautiful people that made the film happen
              with us are Rob Duijker, Daniel Matias Ferrer, Melanie
              Antonides (SMOKE) and Ben King (Earth Works Amsterdam).
            </p>
          </b-col>
          <b-col cols="12" md="3"
                 class="d-flex flex-row flex-md-column text-uppercase align-items-end justify-content-around">
            <router-link :to="{name: 'project', params: {project: 'vaenquish'}}"
                         class="mb-md-3 more-info-modal-button d-flex align-items-start">
              <img src="../assets/img/icon_info.png">
              <div>View Full Project</div>
            </router-link>
            <router-link :to="{name: 'page', params: {category: 'film'}}"
                         class="more-info-modal-button d-flex align-items-start">
              <img src="../assets/img/icon_info.png" alt="">
              <div>View All Film & Video</div>
            </router-link>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: "Index",
    data() {
      return {
        currentCover: 0,
        slideInterval: null,
        cover1: require("../assets/img/v3cover-panzer.jpg"),
        cover2: require("../assets/img/vaenquish_cover.jpg")
      }
    },
    mounted() {
      function preventScroll() {
        document.getElementById('cover-modal').scrollTop = 0;
      }

      this.$refs['coverModal'].$on('shown', () => {
        let coverModal = document.getElementById('cover-modal');
        coverModal.addEventListener('scroll', preventScroll);
        setTimeout(() => {
          coverModal.removeEventListener('scroll', preventScroll);
        }, 900);

      });
      this.slideInterval = setInterval(() => {
        if ((this.currentCover + 1) >= 2) {
          this.currentCover = 0;
        } else {
          this.currentCover++;
        }
        this.changeSlide(this.currentCover);
      }, 15000); // 15s

      setTimeout(() => { // Prevent cover from revealing if previous page is scrolled
        window.scrollTo(0, 0);
        window.addEventListener('scroll', this.handleScroll);
      }, 900)
    },
    beforeRouteLeave(to, from, next) {
      window.removeEventListener('scroll', this.handleScroll);
      if (this.$store.state.isCenterMenuVisible) {
        this.$store.commit('setPreloadCenterMenu', true);
      }
      clearInterval(this.slideInterval);
      next();
    },
    methods: {
      openModal() {
        this.$refs.coverModal.show()
      },
      changeSlide(index) {
        let indicators = this.$el.querySelectorAll('.indicator');
        indicators.forEach(el => {
          el.classList.remove('active');
        });
        this.$refs.coverCarousel.setSlide(index);

        indicators[index].classList.add('active')
      },
      handleScroll() {
        if (window.scrollY >= 1) {
          window.removeEventListener('scroll', this.handleScroll);
          this.goToNextPage()
        }
      },
      openMenu() {
        const menu = document.querySelector('#collapsible-menu');
        menu.classList.add('animated', 'XslideInDown');
        menu.classList.replace('d-none', 'd-block');
        this.$store.state.isCenterMenuVisible = true;

        function handleAnimationEnd() {
          menu.classList.remove('animated', 'XslideInDown');
          menu.removeEventListener('animationend', handleAnimationEnd);
        }

        menu.addEventListener('animationend', handleAnimationEnd)
      },
      goToNextPage() {
        this.$router.push({
          name: 'page',
          params: {
            category: 'home'
          }
        })
      }
    }
  }
</script>

<style scoped>
  /* Animations */

  @-webkit-keyframes coverExitAnimation {
  from {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes coverExitAnimation {
  from {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.coverExitAnimation {
  -webkit-animation-name: coverExitAnimation;
  animation-name: coverExitAnimation;
}

  #index-cover {
    position: relative;
    z-index: 9;
  }

  #index-cover .scroll {
    position: absolute;
    width: 50px;
    bottom: 75px;
    left: 50%;
    margin-left: -25px;
    cursor: pointer;
  }

  #carousel-indicators {
    position: absolute;
    right: 15%;
    top: 15px;
    height: 50px;
    width: 100px;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #carousel-indicators .indicator {
    /*padding: 10px;*/
    color: #b3babe;
    border-radius: 50px;
    font-size: 6px;
    padding: 6px;
    cursor: pointer;
    border: 1px solid transparent;
  }

  #carousel-indicators .indicator.active {
    border: 1px solid #b3babe;
  }

  #logo-wrapper {
    background-color: #000;
    width: 130px;
    padding: 15px 25px;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
  }

  #logo-wrapper img {
    width: 100%;
  }

  #covers-wrapper {
    height: 100.1vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .cover {
    /*display: flex;*/
    width: 100vw;
    height: 100vh;
    background-size: cover !important;
    color: #FFF;
    position: relative;
    background-position: center center !important;
    background-repeat: no-repeat !important;
  }

  .cover .content {
    /*width: 485px;*/
    font-family: proxima nova rg, Montserrat, trebuchet ms, Arial, sans-serif;
    text-transform: uppercase;
  }

  .cover .content .play-btn {
    width: 90px;
    height: 84px;
    cursor: pointer;
    transition: all 0.5s;
  }

  .cover .content .play-btn:hover {
    transform: scale(1.1);
  }

  .cover .content h1 {
    font-size: 48px;
    letter-spacing: 1rem;
    margin-bottom: 6px;
  }

  .cover .content h4 {
    font-size: 18px;
    letter-spacing: 0.5rem;
    margin-bottom: 4px;
    font-weight: 500;
  }

  .cover .content p {
    /*font-weight: 600;*/
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.2rem;
  }

  .cover-header-side-text {
    font-family: Futura, josefin sans, Trebuchet MS, Arial, sans-serif;
    font-size: 11px;
    color: #7ac9f0;
  }

  .modal-footer-text {
    font-size: 14px;
    text-align: center;
    font-style: italic;
    color: #b2b6bd;;
  }

  .more-info-modal-button {
    background-color: #333;
    padding: 4px 8px;
    border: 1px solid #464646;
    text-decoration: none;
    color: #7a7a7a;
    border-radius: 5px;
    transition: all .4s;
    width: 150px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 22px;
  }

  .more-info-modal-button img {
    width: 15px;
    height: 15px;
  }

  .more-info-modal-button:hover {
    border-color: #428caf;
    color: #428caf;
  }

  @media (max-width: 1200px) {
    #carousel-indicators {
      right: 0;
      left: 50%;
      transform: translateX(-50%);
      top: 100px;
    }
  }

  @media (max-width: 767px) {
    #carousel-indicators {
      right: 0;
      left: 50%;
      transform: translateX(-50%);
      top: 50px;
    }
  }
</style>
