<template>
  <div v-if="selectedProductVariant"
       class="store-modal-html-content"
       data-aos="fade-in">
    <b-row no-gutters ref="row">
      <b-col cols="12" md="3"
             offset-md="1"
             order="1"
             order-md="0"
             class="d-flex flex-column justify-content-center align-items-center">
        <!-- mobile -->
        <b-row no-gutters class="px-2 d-flex d-md-none mb-3">
          <b-col :key="index"
                 v-for="(variant, index) in this.product.variants"
                 cols="3">
            <img :src="variant.node.image.thumbnail"
                 :alt="variant.node.title"
                 @click="selectVariant(variant.node)"
                 :selected-variant="selectedProductVariant.id === variant.node.id"
                 :id="variant.node.id"
                 class="variant-thumbnail w-100">
            <b-popover
                :title="variant.node.title"
                :target="variant.node.id"
                placement="top"
                triggers="hover"
            ></b-popover>
          </b-col>
        </b-row>
        <h3 class="product-name">{{product.title}}</h3>
        <p class="product-description mt-2">
          <span v-if="this.product.categoryHandle === 'digital-art'">
            Design by
          </span>
          <span v-else>
            Photograph by
          </span>
          <a href="https://carlosmats.com" target="_blank">Carlos Mats</a>
        </p>
        <h3 class="product-price">${{Number(selectedProductVariant.price.amount)}}
          {{selectedProductVariant.price.currencyCode}}
        </h3>
        <cart-add-button
            :variant-id="selectedProductVariant.id"
            :title="`${product.title} - ${selectedProductVariant.title}`"
            :image-u-r-l="selectedProductVariant.image.url"
            :price="selectedProductVariant.price.amount"
            :click-action="addToCartAnimation"/>
        <p class="small mt-2">
          $2 from each piece
          <br>
          goes to COVID relief
          <br>
          in Honduras and Guatemala
        </p>
        <p :key="option.name"
           v-for="option in selectedProductVariant.selectedOptions"
           class="product-variant-title mt-2">
          {{ option.value }}
        </p>
        <!-- desktop -->
        <b-row no-gutters class="px-2 d-none d-md-flex" style="z-index: 10">
          <b-col :key="index"
                 v-for="(variant, index) in this.product.variants"
                 cols="3">
            <img :src="variant.node.image.thumbnail"
                 :alt="variant.node.title"
                 @click="selectVariant(variant.node)"
                 :selected-variant="selectedProductVariant.id === variant.node.id"
                 :id="variant.node.id"
                 class="variant-thumbnail w-100">
            <b-popover
                :title="variant.node.title"
                :target="variant.node.id"
                placement="top"
                triggers="hover"
            ></b-popover>

          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="8" order="0" order-md="1">
        <div class="selected-variant-image-wrapper d-flex flex-column"
             ref="selected-variant-image-wrapper">
          <img :src="selectedProductVariant.image.url"
               :data-skeleton-loader="require('../assets/img/skeleton.png')"
               ref="selected-variant-image"
               class="selected-variant-image d-block mx-auto"
               alt="Selected Product">
          <img src="../assets/img/rolling_32.svg"
               ref="loading-icon"
               class="loading-icon"
               alt="Loading...">
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import CartAddButton from "./CartAddButton";

  export default {
    name: "StoreProductRow",
    components: {CartAddButton},
    props: {
      product: Object,
    },
    data() {
      return {
        selectedProductVariant: null
      }
    },
    created() {
      this.selectedProductVariant = this.product.variants[0].node;
    },
    mounted() {
      const mainImage = this.$refs['selected-variant-image'];
      const loadingIcon = this.$refs['loading-icon'];
      const tempImage = new Image();
      tempImage.src = mainImage.src;
      mainImage.src = mainImage.dataset.skeletonLoader;
      mainImage.style.transform = 'translateX(-50%) scale(0.1)';
      mainImage.style.position = 'absolute';
      mainImage.style.left = '50%';
      mainImage.style.height = '600px';
      mainImage.style.width = 'auto';
      tempImage.onload = () => {
        mainImage.style.transform = '';
        mainImage.style.position = '';
        mainImage.style.left = '';
        mainImage.style.height = '';
        mainImage.style.width = '';
        mainImage.src = tempImage.src;
        mainImage.classList.add('animated', 'fadeIn');
        loadingIcon.classList.add('d-none');
      }
      let storeProductModal = document.querySelector('#store-modal .modal-body');
      storeProductModal.addEventListener('scroll', this.hideAllPopovers);
    },
    beforeDestroy() {
      let storeProductModal = document.querySelector('#store-modal .modal-body');
      storeProductModal.removeEventListener('scroll', this.hideAllPopovers);
    },
    methods: {
      selectVariant(variant) {
        this.selectedProductVariant = variant;
      },
      hideAllPopovers() {
        this.$root.$emit('bv::hide::popover');
      },
      addToCartAnimation() {
        setTimeout(() => {
          const element = document.querySelector('#modal-store-cart span');
          if (element) {
            element.classList.remove('animated', 'flash');
            setTimeout(() => {
              element.classList.add('animated', 'flash');
            }, 50)
          }
        }, 100)
      }
    }
  }
</script>

<style scoped>

</style>
