<template>
  <b-container fluid :class="`main-content ${$store.state.isStoreVisible ? 'store-style' : ''}`">

    <div :key="10"
         v-if="$route.name === 'about' && !$store.state.search"
         class="faded-background animated fadeIn" style="animation-delay: .5s"
         :style="{background: `${$store.getters.pageBackground}`}"></div>

    <div :key="20"
         v-if="$route.name === 'contact' && !$store.state.search"
         class="faded-background animated fadeIn" style="animation-delay: .5s"
         :style="{background: `${$store.getters.pageBackground}`}"></div>

    <div :key="30"
         v-if="$store.state.isStoreVisible"
         class="store-background faded-background animated fadeIn"
         style="animation-delay: .3s"></div>

    <!-- Mobile top bar -->

    <b-row class="align-items-center justify-content-center py-3 d-flex d-xl-none"
           id="mobile-top-row"
           :style="{background: $store.state.isStoreVisible || $store.state.keepStoreStyles ? '#d0d0d0' :`no-repeat url(${require('../assets/img/bg_with_light.png')})`}">

      <!--      <router-link v-if="!$store.state.isStoreVisible && !$store.state.keepStoreStyles && $store.state.storeData.menu && !isMobileSearchVisible"-->
      <!--                   :class="`text-uppercase mobile-menu-store-link faster ${$store.state.isMobileMenuOpen ? 'animated fadeOut' : 'animated fadeIn'}`"-->
      <!--                   :to="{name: 'store', params: {category: $store.state.storeData.menu[0].handle}}">-->
      <!--        ART STORE-->
      <!--      </router-link>-->

      <a v-if="$store.state.isStoreVisible || $store.state.keepStoreStyles || $store.getters.cartSize > 0"
         href="#" class="cart" @click.prevent="openShoppingCartModal()">
        <img v-if="($store.getters.cartSize > 0) && ($store.state.isStoreVisible || $store.state.keepStoreStyles)"
             src="../assets/img/xs-store-cart-black.png" alt="Shopping Cart">
        <img v-else-if="$store.getters.cartSize === 0 && ($store.state.isStoreVisible || $store.state.keepStoreStyles)"
             src="../assets/img/xs-store-cart-gray.png" alt="Shopping Cart">
        <img v-else-if="$store.getters.cartSize > 0 && !$store.state.isStoreVisible && !$store.state.keepStoreStyles"
             src="../assets/img/xs-store-cart-white.png" alt="Shopping Cart">
        <span v-if="$store.getters.cartSize > 0" class="badge badge-danger">
                {{ $store.getters.cartSize }}
        </span>
      </a>

      <b-col cols="10">
        <h5 :class="`m-0 page-title ${$store.state.isStoreVisible || $store.state.keepStoreStyles ? 'store-style' : ''}`">
          {{ $store.getters.menuTitle }}
          <br>
          <small v-if="!$store.state.isStoreVisible">
            {{ ['about', 'contact'].includes($route.name) ? 'Xchematic' : 'Portfolio' }}
          </small>
          <small v-else>
            {{ $store.getters.storeMenuTitle }}
          </small>
        </h5>
      </b-col>

      <i class="fa fa-search search-icon"
         :style="{color: `${$store.state.isStoreVisible || $store.state.keepStoreStyles ? '#000' : '#fff'}`}"
         @click="toggleMobileSearchForm"
         title="Search Projects"></i>

      <b-col cols="9" class="animated faster d-none search-wrapper">
        <form
            :class="`search-form w-100 ${$store.state.isStoreVisible || $store.state.keepStoreStyles ? 'store-style' : ''}`">
          <div class="position-relative">
            <input type="text" autocomplete="off" v-model="search"
                   class="w-100"
                   @keyup="performSearch"
                   placeholder="SEARCH...">
            <i v-if="$store.state.search"
               @click="clearSearch"
               class="fa fa-times-circle"></i>
          </div>
        </form>
      </b-col>
    </b-row>

    <b-row class="invisible-mobile-top-row d-xl-none"></b-row>

    <!-- Desktop top bar -->
    <b-row
        :class="`align-items-center pr-2 d-none d-xl-flex ${$store.state.isStoreVisible || $store.state.keepStoreStyles ? 'store-style' : ''}`"
        id="top-row">
      <b-col cols="6" class="d-flex justify-content-start">
        <h2 v-if="!$store.state.isStoreVisible"
            class="pl-4 m-0 page-title">
          {{ $store.getters.menuTitle }}
        </h2>
        <h2 v-else
            class="pl-4 m-0 page-title">
          {{ $store.getters.storeMenuTitle }}
        </h2>
      </b-col>
      <b-col cols="6" class="d-flex justify-content-end">
        <form
            :class="`search-form ${$store.state.isStoreVisible || $store.state.keepStoreStyles ? 'store-style' : ''}`">
          <div class="position-relative">
            <input type="text" name="search" autocomplete="off" v-model="search"
                   @keyup="performSearch"
                   placeholder="SEARCH...">
            <i v-if="$store.state.search"
               @click="clearSearch"
               class="fa fa-times-circle"></i>
          </div>
        </form>
      </b-col>
    </b-row>

    <b-row id="main-container"
           class="align-content-start"
           :style="`${!$store.state.isStoreVisible && !$store.state.keepStoreStyles ? 'background-image: linear-gradient(#2d424c, #000);' : ''} ${$store.state.search ? 'min-height: 100vh;' : ''}`">

      <b-col v-if="$route.name === 'contact' && !$store.state.search"
             class="pr-1 pl-1 pt-1 mt-5 d-flex flex-wrap align-content-start" lg="5" md="6"
             :style="`color: ${$store.state.keepStoreStyles ? '#000' : '#fff'} `">
        <b-row no-gutters class="d-flex align-content-start">
          <b-col cols="12" md="11"
                 class="text-md-left text-center offset-0 offset-md-1 mb-5 animated fadeIn px-1"
                 style="animation-delay: 0.2s">
            <h5 class="content-title">WE'RE IN THE BUSINESS OF CREATING COOL STUFF</h5>
            <small>It's our calling.</small>
          </b-col>
          <b-col cols="12" md="11"
                 class="text-md-left offset-md-1 offset-0">
            <b-row>
                <b-col>
                    <h6 class="content-subtitle" style="text-align: center;">AROUND THE WORLD</h6>
                </b-col>
            </b-row>
            <!--
            <b-row>
              <b-col md="4" class="animated fadeIn" style="animation-delay: 0.4s">
                <h6 class="content-subtitle">AMSTERDAM</h6>
                <p class="mb-0 body-text">
                  Karperweg 16<br>
                  Amsterdam 1075LC<br>
                  The Netherlands<br>
                  <strong>+31 6 11475648</strong><br>
                </p>
                <p class="mt-0 body-text">info [at] xchematic.com</p>
              </b-col>
              <b-col md="4" class="animated fadeIn" style="animation-delay: 0.6s">
                <h6 class="content-subtitle">NEW YORK</h6>
                <p class="mb-0 body-text">17 Orange Ave<br>
                  Goshen, NY, 10924-2117<br>
                  United States</p>
                <p class="mt-0 body-text">info [at] xchematic.com</p>
              </b-col>
              <b-col md="4" class="animated fadeIn" style="animation-delay: 0.8s">
                <h6 class="content-subtitle">COSTA RICA</h6>
                <p class="mb-0 body-text">Heredia, Belén, San Antonio, Hacienda Belén, 36i</p>
                <strong class="body-text">+506 6058-9746</strong>
                <p class="mt-0 body-text">info [at] xchematic.com</p>
              </b-col>
            </b-row>
            <b-row class="mt-4 animated fadeIn" style="animation-delay: 1s">
              <b-col md="4">
                <h6 class="content-subtitle">ROATÁN</h6>
                <p class="mb-0 body-text">West Bay Beach</p>
                <strong class="body-text">
                  +504 9645-7189‬
                  <br>
                  WhatsApp
                </strong>
                <p class="mt-0 body-text">info [at] xchematic.com</p>
              </b-col>
              <b-col md="4">
                <h6 class="content-subtitle">GUATEMALA</h6>
                <p class="mb-0 body-text">
                  Edificio Rue 3 Vía 6,
                  <br>
                  3-09 4 Grados Norte Zona 4
                  <br>
                  Ciudad Guatemala
                </p>
                <strong class="body-text">
                  +502 5564-4591
                  <br>
                  WhatsApp
                </strong>
                <p class="mt-0 body-text">info [at] xchematic.com</p>
              </b-col>
            </b-row>
            -->
          </b-col>
          <b-col cols="12"
                 class="d-flex justify-content-center align-items-center mt-4 animated fadeIn contact-social-icons"
                 style="animation-delay: 1s">
            <table class="social_tbl">
              <tbody>
              <tr>
                <td>
                  <a href="https://www.facebook.com/Xchematic/" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-facebook-f" title="Facebook"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://www.behance.net/xchematic" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-behance" title="Behance"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://twitter.com/xchematic" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-twitter" title="Twitter"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://medium.com/@Xchematic" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-medium" title="Medium"></i>
                    </div>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.linkedin.com/company/xchematic" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-linkedin" title="LinkedIn"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://vimeo.com/xchematic" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-vimeo" title="Vimeo"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://www.youtube.com/user/Xchematic" target="_blank">
                    <div class="scl_item hvr_grow">
                      <i class="fab fa-youtube" title="Youtube"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://www.instagram.com/xchematic/" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-instagram" title="Instagram"></i>
                    </div>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="http://xchematic.tumblr.com" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-tumblr" title="Tumblr"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://www.flickr.com/photos/xchematic/" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-flickr" title="Flickr"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://dribbble.com/xchematic" target="_blank">
                    <div class="scl_item hvr_grow">
                      <i class="fab fa-dribbble" title="Dribbble"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="mailto:team@xchematic.com">

                    <div class="scl_item hvr_grow">
                      <i class="fa fa-envelope" title="Email"></i>
                    </div>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>

      <b-col v-else-if="$route.name === 'about' && !$store.state.search"
             :class="`pr-1 pl-1 pt-1 d-flex align-items-start flex-wrap ${$store.state.keepStoreStyles ? 'store-style' : ''}`"
             lg="5" md="6"
             :style="`color: ${$store.state.keepStoreStyles ? '#000' : '#fff'}`">
        <b-row class="mt-0 mt-lg-5 text-left offset-1"
               no-gutters>
          <b-col :key="Math.floor(Math.random() * Math.floor(1000))"
                 cols="11"
                 class="mt-5 animated fadeIn">
            <h5 class="content-title">
              OUR GOAL IS TO CREATE UNIQUE WORKS OF ART
            </h5>
            <p class="body-text">
              Founded in 2004 by Carlos Mats, Xchematic is an arts & technology
              studio focused on design and innovation, with 19 years of
              experience in software, web, branding, photography,
              virtual reality, artificial intelligence, and film production.
            </p>
            <p class="body-text">
              Started in Honduras, now we have representation
              in New York, Amsterdam, Costa Rica and Guatemala.
            </p>
          </b-col>

          <b-col :key="Math.floor(Math.random() * Math.floor(1000))"
                 cols="12" md="5" class="mt-4 animated fadeIn"
                 style="animation-delay: 0.2s">
            <h5 class="content-title">
              <a href="https://carlosmats.com" target="_blank">
                <span>
                  CARLOS MATS
                </span>
                <img src="../assets/img/cnt_linkedin.png"
                     style="width: 32px"
                     alt="Carlos Mats LinkedIn Profile"
                     class="social-media-hover">
              </a>
            </h5>
            <p class="body-text">FOUNDER, DESIGNER & CEO</p>
            <p class="body-text">Artist, designer, filmmaker, director,
              photographer and diver.
              Specialized in UX design, branding, and marketing.
              <br>
              Net Neutrality supporter. Founded Xchematic as a primary
              creative outlet, transformed it into a life mission.
            </p>
          </b-col>
<!--          <b-col :key="Math.floor(Math.random() * Math.floor(1000))"-->
<!--                 cols="12" md="5" class="offset-md-1 offset-0 mt-4 animated fadeIn"-->
<!--                 style="animation-delay: 0.4s">-->
<!--            <h5 class="content-title">-->
<!--              <a href="https://www.linkedin.com/in/carlosrobertoaguilar/"-->
<!--                 target="_blank">-->
<!--                <span>-->
<!--                  CARLOS AGUILAR-->
<!--                </span>-->
<!--                <img src="../assets/img/cnt_linkedin.png"-->
<!--                     style="width: 32px"-->
<!--                     alt="Carlos Mats LinkedIn Profile"-->
<!--                     class="social-media-hover">-->
<!--              </a>-->
<!--            </h5>-->
<!--            <p class="body-text">PARTNER</p>-->
<!--            <p class="body-text">-->
<!--              Accomplished IT professional with experience in multiple aspects-->
<!--              of information technology and management, including large-->
<!--              multinational organizations, consulting, and running startups.-->
<!--              Ample experience with entire software development-->
<!--              lifecycle, project and program management, finance,-->
<!--              budgeting, ERP implementations, financial systems,-->
<!--              supply chain projects, payroll system implementations,-->
<!--              billing systems, collaboration platforms and business-->
<!--              process management systems implementations.-->
<!--            </p>-->
<!--          </b-col>-->
          <b-col :key="Math.floor(Math.random() * Math.floor(1000))"
                 cols="12" md="5" class="mt-4 animated fadeIn offset-md-1"
                 style="animation-delay: 0.6s">
            <h5 class="content-title d-flex align-items-center justify-content-between">
              <a href="https://www.linkedin.com/in/ccerrato147/"
                 target="_blank">
                <span>
                  CARLOS CERRATO
                </span>
                <img src="../assets/img/cnt_linkedin.png"
                     style="width: 32px"
                     alt="Carlos Mats LinkedIn Profile"
                     class="social-media-hover">
              </a>
            </h5>
            <p class="body-text">PARTNER / SOFTWARE ARCHITECT</p>
            <p class="body-text">
              Specialized is creating custom-tailored solutions using both
              open sourced and licensed software, from cloud native
              micro-services, process automation, content management,
              business intelligence solutions, enterprise web sites
              and many more. Transhumanist and AI Engineer.
            </p>
          </b-col>
          <b-col cols="12" class="pt-3 animated fadeIn"
                 style="animation-delay: 0.8s">
            <div>
              <h5 class="content-title">Press</h5>
              <p :key="p.url" class="about-bullet-points text-left bullet-overflow"
                 v-for="p in $store.getters.press">
                <a :href="p.url"
                   target="_blank"
                   class="no-underline">
                  - {{ p.title }}
                </a>
              </p>
            </div>
          </b-col>
        </b-row>
      </b-col>


      <b-col
          v-else-if="(['page', 'project'].includes($route.name) || $store.state.search) && (!$store.state.isStoreVisible && !$store.state.keepStoreStyles)"
          :key="projectKeys[0]"
          data-aos="fade-in"
          data-aos-delay="200"
          class="px-1 pt-1 pt-lg-1 d-flex flex-wrap align-content-start position-relative projects-column"
          xl="5" md="6">
        <template v-if="$store.getters.featuredEntries('left').length === 0 && !$store.state.search">
          <b-col cols="12" class="column-skeleton-loader">
            <img :src="loadingIcon"
                 alt="Skeleton loader">
          </b-col>
          <b-col cols="12" class="column-skeleton-loader">
            <img :src="loadingIcon"
                 alt="Skeleton loader">
          </b-col>
          <b-col cols="12" class="column-skeleton-loader">
            <img :src="loadingIcon"
                 alt="Skeleton loader">
          </b-col>
        </template>
        <router-link :to="{name: 'project', params: {project: data.url}}"
                     v-for="data in $store.getters.featuredEntries('left')"
                     :key="data.url"
                     data-aos="fade-in"
                     class="project-cover highlight">
          <lazy-background class="thumbnail-cover"
                           :title="data.title"
                           :subtitle="data.short_description"
                           :background-src="data.cover_img"
                           :empty-background-src="loadingIcon"
                           :show-corner="true"
                           :is-new="data.is_new"
                           back-color="#2b7da5"
                           border-color="white-border"
          />
        </router-link>

        <router-link :to="{name: 'project', params: {project: data.url}}"
                     v-for="data in $store.getters.entries('left')"
                     :key="data.url"
                     data-aos="fade-in"
                     class="project-cover secondary">
          <lazy-background class="thumbnail-cover"
                           :title="data.title"
                           :subtitle="data.short_description"
                           :background-src="data.cover_img"
                           :empty-background-src="loadingIcon"
                           :show-corner="true"
                           :is-new="data.is_new"
                           back-color="#2b7da5"
                           border-color="white-border"
          />
        </router-link>
        <div v-if="$store.state.search && $store.getters.entries('left').length === 0"
             class="w-100 mt-5">
          <h4 class="text-center text-white">
            No search results,
            <br>
            <a href="#" @click.prevent="clearSearch">back to browsing</a>
          </h4>
        </div>

      </b-col>


      <b-col v-else-if="$store.state.isStoreVisible || $store.state.keepStoreStyles"
             data-aos="fade-in"
             class="px-1 px-xl-2 pt-2 d-flex flex-wrap"
             style="background-color: #fff"
             xl="5" md="6">
        <Store
            v-if="($route.params.category !== 'exhibit' || $store.state.search) && ($store.getters.products('left').length > 0) && !isMobile"
            :products="$store.getters.products('left')"
            :clear-search-fn="clearSearch"
            side="left"
            :open-store-modal="this.openStoreModal"/>

        <Store v-if="($route.params.category !== 'exhibit' || $store.state.search) && isMobile"
               :products="$store.getters.mobileProducts"
               :clear-search-fn="clearSearch"
               side="left"
               :open-store-modal="this.openStoreModal"/>

        <div v-if="flashForwardEntry"
             data-aos="fade-in"
             data-category-handle="exhibit"
             @click="openModal(flashForwardEntry.url)"
             class="project-cover highlight">
          <lazy-background class="thumbnail-cover"
                           :title="this.flashForwardEntry.title"
                           :subtitle="this.flashForwardEntry.short_description"
                           :background-src="this.flashForwardEntry.cover_img"
                           :empty-background-src="loadingIcon"
                           :show-corner="true"
                           :is-new="'no'"
                           back-color="#2b7da5"
                           border-color="white-border"
          />
        </div>
      </b-col>


      <!-- Invisible column that serves as placeholder for the fixed menu -->
      <b-col id="hidden-center-menu" lg="2"
             :style="{height: $store.state.search ? '95vh' : ''}"
             :class="`d-none d-lg-block p-0 ${['about', 'contact'].includes($store.state.category) ? 'full-height-hidden-menu' : ''}`"></b-col>


      <b-col v-if="$route.name === 'contact' && !$store.state.search" class="pb-5"
             :class="$store.state.keepStoreStyles ? 'store-style' : ''">
        <b-row class="mt-5 animated fadeIn" style="animation-delay: 1.2s" no-gutters>
          <b-col cols="12">
            <b-form id="contact-form">
              <b-form-row>
                <b-col cols="6">
                  <label class="sr-only">Name</label>
                  <b-input
                      class="m-0"
                      v-model="contactData.name"
                      type="text"
                      placeholder="FULL NAME *"
                  ></b-input>
                </b-col>
                <b-col cols="6">
                  <label class="sr-only">Email</label>
                  <b-input
                      class="m-0"
                      v-model="contactData.email"
                      type="email"
                      placeholder="EMAIL *"
                  ></b-input>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col cols="6" class="mt-4">
                  <label class="sr-only">COMPANY / ORGANIZATION</label>
                  <b-input
                      v-model="contactData.company"
                      class="m-0"
                      type="text"
                      placeholder="COMPANY / ORGANIZATION *"
                  ></b-input>
                </b-col>
                <b-col cols="6" class="mt-4">
                  <label class="sr-only">Country</label>
                  <b-form-select
                      class="m-0"
                      v-model="contactData.country"
                      :options="contactCountries"
                      value-field="value"
                      text-field="value">
                    <b-form-select-option :value="null">Country *</b-form-select-option>
                  </b-form-select>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col cols="12" class="mt-4 mb-4">
                  <h5 :class="`text-center ml-1 ${$store.state.keepStoreStyles ? 'text-black-50' : 'text-white'}`">
                    I'M INTERESTED IN:
                  </h5>
                </b-col>

                <b-col cols="12">
                  <div class="check_col">
                    <div class="col_1">
                      <input type="checkbox" v-model="contactData.interests" id="intrst_1"
                             value="web">
                      <label for="intrst_1"><span></span></label>
                    </div>
                    <div class="col_2">
                      <div class="col_cell">WEB DESIGN<br>&amp; DEVELOPMENT</div>
                    </div>
                  </div>
                  <div class="check_col">
                    <div class="col_1">
                      <input type="checkbox" v-model="contactData.interests" id="intrst_2"
                             value="saas">
                      <label for="intrst_2"><span></span></label>
                    </div>
                    <div class="col_2">
                      <div class="col_cell">SAAS DESIGN<br>&amp; DEVELOPMENT</div>
                    </div>
                  </div>
                  <div class="check_col">
                    <div class="col_1">
                      <input type="checkbox" v-model="contactData.interests" id="intrst_3"
                             value="mobile">
                      <label for="intrst_3"><span></span></label>
                    </div>
                    <div class="col_2">
                      <div class="col_cell">MOBILE APP DESIGN<br>&amp; DEVELOPMENT</div>
                    </div>
                  </div>
                  <div class="check_col">
                    <div class="col_1">
                      <input type="checkbox" v-model="contactData.interests" id="intrst_4"
                             value="print">
                      <label for="intrst_4"><span></span></label>
                    </div>
                    <div class="col_2">
                      <div class="col_cell">PRINT DESIGN</div>
                    </div>
                  </div>
                  <div class="check_col">
                    <div class="col_1">
                      <input type="checkbox" v-model="contactData.interests" id="intrst_5"
                             value="branding">
                      <label for="intrst_5"><span></span></label>
                    </div>
                    <div class="col_2">
                      <div class="col_cell">BRANDING</div>
                    </div>
                  </div>
                  <div class="check_col">
                    <div class="col_1">
                      <input type="checkbox" v-model="contactData.interests" id="intrst_6"
                             value="photography">
                      <label for="intrst_6"><span></span></label>
                    </div>
                    <div class="col_2">
                      <div class="col_cell">PHOTOGRAPHY</div>
                    </div>
                  </div>
                  <div class="check_col">
                    <div class="col_1">
                      <input type="checkbox" v-model="contactData.interests" id="intrst_7"
                             value="video">
                      <label for="intrst_7"><span></span></label>
                    </div>
                    <div class="col_2">
                      <div class="col_cell">VIDEO DIRECTION<br>&amp; PRODUCTION</div>
                    </div>
                  </div>
                  <div class="check_col">
                    <div class="col_1">
                      <input type="checkbox" v-model="contactData.interests" id="intrst_8"
                             value="film">
                      <label for="intrst_8"><span></span></label>
                    </div>
                    <div class="col_2">
                      <div class="col_cell">FILM DIRECTION<br>&amp; PRODUCTION</div>
                    </div>
                  </div>
                  <div class="check_col">
                    <div class="col_1">
                      <input type="checkbox" v-model="contactData.interests" id="intrst_9"
                             value="speaking">
                      <label for="intrst_9"><span></span></label>
                    </div>
                    <div class="col_2">
                      <div class="col_cell">SPEAKING<br>ENGAGEMENTS</div>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" class="mt-4">
                  <b-form-textarea
                      class="w-100 m-0"
                      v-model="contactData.about"
                      placeholder="TELL US ABOUT THE PROJECT *">
                  </b-form-textarea>
                </b-col>
                <b-col cols="12" md="8" class="mt-5 offset-md-2 d-flex justify-content-start">
                  <b-dropdown id="currency-dropdown" :text="contactData.currency" class="m-0 h-100">
                    <b-dropdown-item @click="contactData.currency = '$'">$</b-dropdown-item>
                    <b-dropdown-item @click="contactData.currency = '€'">€</b-dropdown-item>
                  </b-dropdown>
                  <b-form-input
                      v-model="contactData.budget"
                      @keypress="isNumber($event)"
                      class="m-0 h-100 budget-input"
                      placeholder="APPROXIMATED BUDGET (IF APPLICABLE)">
                  </b-form-input>
                </b-col>
                <b-col cols="12" md="8" class="mt-4 offset-md-2">
                  <b-button class="w-100 submit-btn"
                            :disabled="contactData.sending"
                            @click.prevent="sendEmail">
                    Send
                  </b-button>
                </b-col>
              </b-form-row>
            </b-form>
          </b-col>
        </b-row>
      </b-col>

      <b-col v-else-if="$route.name === 'about' && !$store.state.search"
             :style="`color: ${$store.state.keepStoreStyles ? '#000' : '#fff'}`"
             :class="`pb-5 ${$store.state.keepStoreStyles ? 'store-style' : ''}`">
        <b-row class="mt-5">
          <b-col cols="12" class="mb-5 animated fadeIn"
                 style="animation-delay: 0.8s">
            <h5 class="content-title">ACCOLADES AND AWARDS</h5>
          </b-col>

          <b-col cols="12" md="6"
                 class="d-flex align-items-start text-left animated fadeIn"
                 style="animation-delay: 0.8s">
            <img class="accolade-image mr-2"
                 src="../assets/img/bid-accolade.png">
            <a href="https://www.bid-dimad.org/seleccionadosbid20/#1602145989378-0e4869e1-3513"
               target="_blank" class="award-link">
              <h5 class="content-subtitle">
                BIENAL IBEROAMERICANA DE DISEÑO, MADRID 2020: OFFICIAL SELECTION
              </h5>
              <p class="body-text">
                A true honor to have been selected to be part of the
                7th Bienal Iberoamericana de Diseño, Madrid 2020 (BID20),
                with our “Honduras VR” virtual reality application,
                in the “Digital Design” category.
              </p>
              <p class="body-text">
                2020
              </p>
            </a>
          </b-col>

          <b-col cols="12" md="6"
                 class="d-flex align-items-start text-left animated fadeIn"
                 style="animation-delay: 0.8s">
            <img class="accolade-image mr-2"
                 src="../assets/img/longhornsiconPNG.png">
            <a href="https://www.youtube.com/watch?v=WZTXMCwkPOo"
               target="_blank" class="award-link">
              <h5 class="content-subtitle">
                GUEST LECTURER UNIVERSITY OF TEXAS AT AUSTIN
              </h5>
              <p class="body-text">
                Highly honored for the invitation to lecture at
                The University of Texas at Austin, College of Fine Arts,
                Center for Creative Entrepreneurship.

                "Raised by The Internet: Lessons Learned in
                19 Years of Career with Xchematic"
              </p>
              <p class="body-text">
                2020
              </p>
            </a>
          </b-col>

          <b-col cols="12" md="6"
                 class="d-flex align-items-start text-left animated fadeIn"
                 style="animation-delay: 0.8s">
            <img class="accolade-image mr-2"
                 src="../assets/img/cbc-logo.png">
            <a href="https://www.elheraldo.hn/entretenimiento/1300938-466/as%C3%AD-carlos-mats-represent%C3%B3-a-honduras-en-el-creative-business-cup-de"
               target="_blank" class="award-link">
              <h5 class="content-subtitle">
                NATIONAL WINNERS CREATIVE BUSINESS CUP
              </h5>
              <p class="body-text">
                We were selected as National Winners,
                and chosen to represent the country in the
                Global Finals of the Creative Business Cup
                in Copenhagen, Denmark, with our Honduras VR / Mundo VR Project.
                An honor to once bear the flag of our homeland with pride.
              </p>
              <p class="body-text">
                2019
              </p>
            </a>
          </b-col>
          <b-col cols="12" md="6" class="d-flex align-items-start text-left animated fadeIn"
                 style="animation-delay: 1s">
            <img class="accolade-image mr-2"
                 src="../assets/img/YALI_LOGO-1-wht.png">
            <a href="https://youtu.be/rJq4XU-PFs4" target="_blank"
               class="award-link">
              <h5 class="content-subtitle">
                YLAI PROFESSIONAL FELLOWS
              </h5>
              <p class="body-text">
                Selected by the U.S. Department of State Bureau of
                Educational and Cultural Affairs and Meridian
                International Center, Carlos Mats was named
                Young Leaders of the Americas Initiative (YLAI)
                Professional Fellow for the 2018 cohort.
              </p>
              <p class="body-text">
                2018
              </p>
            </a>
          </b-col>
          <b-col cols="12" md="6" class="d-flex align-items-start text-left animated fadeIn"
                 style="animation-delay: 1.2s">
            <img class="accolade-image mr-2"
                 src="../assets/img/austincouncilJPG.jpg">
            <a href="https://wacaustin.org/event/third-annual-texas-latin-america-business-summit/"
               target="_blank" class="award-link">
              <h5 class="content-subtitle">
                SPEAKERS AT THE THIRD ANNUAL TEXAS-LATIN AMERICA BUSINESS SUMMIT, AUSTIN
              </h5>
              <p class="body-text">
                Invited by the World Affairs Council Austin,
                Carlos Mats was a speaker at the Third Annual
                Texas-Latin America Business Summit in the
                "Spotlight on LATAM Founders" panel at the IBC Bank Community Suite.
              </p>
              <p class="body-text">
                2018
              </p>
            </a>
          </b-col>
          <b-col cols="12" md="6" class="d-flex align-items-start text-left animated fadeIn"
                 style="animation-delay: 1.4s">
            <img class="accolade-image mr-2"
                 src="../assets/img/CAIFF-logo.png">
            <a href="https://www.facebook.com/pg/Xchematic/photos/?tab=album&album_id=1518342108212169"
               target="_blank" class="award-link">
              <h5 class="content-subtitle">
                OFFICIAL SELECTION 3RD CENTRAL AMERICAN INTERNATIONAL FILM FESTIVAL IN HOLLYWOOD
              </h5>
              <p class="body-text">
                An honor to represent Honduras with our short film Vaenquish,
                as Official Selection of the Central American
                Film Festival 2017, at The Los Angeles Film School
                in Hollywood, California.
              </p>
              <p class="body-text">
                2017
              </p>
            </a>
          </b-col>
          <b-col cols="12" md="6" class="d-flex align-items-start text-left animated fadeIn"
                 style="animation-delay: 1.6s">
            <img class="accolade-image mr-2"
                 src="../assets/img/cinematography.png">
            <a href="https://www.elheraldo.hn/especiales/festivaldecortometrajes2016/1017587-468/santitos-es-el-mejor-corto-del-v-festival-de-el-heraldo?mainImg=10"
               target="_blank" class="award-link">
              <h5 class="content-subtitle">
                BEST CINEMATOGRAPHY AWARD
              </h5>
              <p class="body-text">
                Award granted by Diario El Heraldo in
                their V FESTIVAL INTERNACIONAL DE CORTOMETRAJES
                where our film Vaenquish won two awards.
              </p>
              <p class="body-text">
                2016
              </p>
            </a>
          </b-col>
          <b-col cols="12" md="6" class="d-flex align-items-start text-left animated fadeIn"
                 style="animation-delay: 1.8s">
            <img class="accolade-image mr-2"
                 src="../assets/img/cinematography.png">
            <a href="https://www.elheraldo.hn/especiales/festivaldecortometrajes2016/1017587-468/santitos-es-el-mejor-corto-del-v-festival-de-el-heraldo?mainImg=11"
               target="_blank" class="award-link">
              <h5 class="content-subtitle">
                BEST EDITING AWARD
              </h5>
              <p class="body-text">
                Award granted by Diario El Heraldo in
                their V FESTIVAL INTERNACIONAL DE CORTOMETRAJES
                where our film Vaenquish won two awards.
              </p>
              <p class="body-text">
                2016
              </p>
            </a>
          </b-col>
          <b-col cols="12" md="6" class="d-flex align-items-start text-left animated fadeIn"
                 style="animation-delay: 2s">
            <img class="accolade-image mr-2"
                 src="../assets/img/startup.png">
            <a href="http://www.up.co/communities/honduras/tegucigalpa/startup-weekend/4994"
               target="_blank" class="award-link">
              <h5 class="content-subtitle">
                NAMED "MENTORS" BY GOOGLE STARTUP WEEKEND
              </h5>
              <p class="body-text">
                We were invited to be Mentors in Google's
                first Startup Weekend in Tegucigalpa,
                and have been participating in every event since.
              </p>
              <p class="body-text">
                2014, 2015, 2016
              </p>
            </a>
          </b-col>
          <b-col cols="12" md="6" class="d-flex align-items-start text-left animated fadeIn"
                 style="animation-delay: 2.2s">
            <img class="accolade-image mr-2"
                 src="../assets/img/ihch.png">
            <a href="https://www.facebook.com/cultura.hispanica.7" target="_blank"
               class="award-link">
              <h5 class="content-subtitle">
                IHCH HONORARY BOARD MEMBERS
              </h5>
              <p class="body-text">
                We were named Honorary Board Members by
                Instituto Hondureño de Cultura Hispánica
                (IHCH) for our continuous technological support for over a decade.
              </p>
              <p class="body-text">
                2012
              </p>
            </a>
          </b-col>
          <b-col cols="12" md="6" class="d-flex align-items-start text-left animated fadeIn"
                 style="animation-delay: 2.4s">
            <img class="accolade-image mr-2"
                 src="../assets/img/unitech.png">
            <a href="http://www.unitec.edu/" target="_blank" class="award-link">
              <h5 class="content-subtitle">
                UNITEC AWARD OF APPRECIATION
              </h5>
              <p class="body-text">
                Award of Appreciation by the Technological University
                of Central America (UNITEC) after we imparted
                a couple of free lectures about Web Technology & Marketing
              </p>
              <p class="body-text">
                2010
              </p>
            </a>
          </b-col>
          <b-col cols="12" md="6" class="d-flex align-items-start text-left animated fadeIn"
                 style="animation-delay: 2.6s">
            <img class="accolade-image mr-2"
                 src="../assets/img/arroba.png">
            <a href="http://www.arrobadeoro.com/certamen/2010/sv/default.aspx" target="_blank"
               class="award-link">
              <h5 class="content-subtitle">
                ARROBA DE ORO FINALISTS
              </h5>
              <p class="body-text">
                FInalists in the "Best Website"
                and "Best Services Website"
                Categories for xchematic.com
              </p>
              <p class="body-text">
                2006
              </p>
            </a>
          </b-col>
        </b-row>
      </b-col>


      <b-col
          v-else-if="(['page', 'project'].includes($route.name) || $store.state.search) && (!$store.state.isStoreVisible && !$store.state.keepStoreStyles)"
          :key="projectKeys[1]"
          data-aos="fade-in"
          data-aos-delay="500"
          :class="`px-1 pt-1 flex-wrap align-content-start position-relative projects-column ${$store.getters.entries('right').length === 0 && $store.state.search ? 'd-none' : 'd-flex'}`"
          xl="5" md="6">
        <template v-if="$store.getters.featuredEntries('right').length === 0 && !$store.state.search">
          <b-col cols="12" class="column-skeleton-loader">
            <img :src="loadingIcon"
                 alt="Skeleton loader">
          </b-col>
          <b-col cols="12" class="column-skeleton-loader">
            <img :src="loadingIcon"
                 alt="Skeleton loader">
          </b-col>
          <b-col cols="12" class="column-skeleton-loader">
            <img :src="loadingIcon"
                 alt="Skeleton loader">
          </b-col>
        </template>
        <router-link :to="{name: 'project', params: {project: data.url}}"
                     v-for="data in $store.getters.featuredEntries('right')"
                     :key="data.url"
                     data-aos="fade-in"
                     class="project-cover highlight">
          <lazy-background class="thumbnail-cover"
                           :title="data.title"
                           :subtitle="data.short_description"
                           :background-src="data.cover_img"
                           :empty-background-src="loadingIcon"
                           :show-corner="true"
                           :is-new="data.is_new"
                           back-color="#2b7da5"
                           border-color="white-border"
          />
        </router-link>
        <router-link :to="{name: 'project', params: {project: data.url}}"
                     v-for="data in $store.getters.entries('right')"
                     :key="data.url"
                     data-aos="fade-in"
                     class="project-cover secondary">
          <lazy-background class="thumbnail-cover"
                           :title="data.title"
                           :subtitle="data.short_description"
                           :background-src="data.cover_img"
                           :empty-background-src="loadingIcon"
                           :show-corner="true"
                           :is-new="data.is_new"
                           back-color="#2b7da5"
                           border-color="white-border"
          />
        </router-link>
        <div v-if="$store.state.search && $store.getters.entries('right').length === 0"
             class="w-100 mt-5">
          <h4 class="text-center text-white">
            No search results,
            <br>
            <a href="#" @click.prevent="clearSearch">back to browsing</a>
          </h4>
        </div>
      </b-col>


      <b-col v-else-if="$store.state.isStoreVisible || $store.state.keepStoreStyles"
             data-aos="fade-in"
             data-aos-delay="300"
             class="px-1 px-xl-2 pt-2 flex-wrap d-flex"
             style="background-color: #fff"
             xl="5" md="6">
        <Store v-if="!isMobile"
               :products="$store.getters.products('right')"
               side="right"
               :clear-search-fn="clearSearch"
               :open-store-modal="this.openStoreModal"/>
      </b-col>


      <b-col v-if="['page', 'project', 'store'].includes($route.name) && !$store.state.search"
             class="d-flex flex-column flex-md-row px-1">
        <div class="column-footer">
          <footer-element :elements="featuredFooterClients"
                          footer-title="Featured Clients"
                          :open-modal-fn="openModal"
                          class="footer-element animated fadeIn"></footer-element>
          <footer-element :elements="featuredFooterProjects"
                          footer-title="Featured Projects"
                          :open-modal-fn="openModal"
                          class="footer-element animated fadeIn"></footer-element>
        </div>
        <div class="d-none d-xl-block col-2"></div>
        <div class="column-footer">
          <div class="footer-element" id="footer-video" @click="openFooterVideo">
            <h4 class="footer-title">DEMO REEL (2012 CLASSIC)</h4>
            <img src="../assets/img/demo_reel_th.png" alt="" class="video-bg">
            <img src="../assets/img/play-icon.png" alt="" class="play-icon">
          </div>
          <div class="footer-element" id="footer-social-links">
            <h4 class="footer-title">GET IN TOUCH</h4>
            <table class="social_tbl">
              <tbody>
              <tr>
                <td>
                  <a href="https://www.facebook.com/Xchematic/" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-facebook-f" title="Facebook"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://www.behance.net/xchematic" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-behance" title="Behance"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://twitter.com/xchematic" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-twitter" title="Twitter"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://medium.com/@Xchematic" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-medium" title="Medium"></i>
                    </div>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="https://www.linkedin.com/company/xchematic" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-linkedin" title="LinkedIn"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://vimeo.com/xchematic" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-vimeo" title="Vimeo"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://www.youtube.com/user/Xchematic" target="_blank">
                    <div class="scl_item hvr_grow">
                      <i class="fab fa-youtube" title="Youtube"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://www.instagram.com/xchematic/" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-instagram" title="Instagram"></i>
                    </div>
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a href="http://xchematic.tumblr.com" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-tumblr" title="Tumblr"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://www.flickr.com/photos/xchematic/" target="_blank">

                    <div class="scl_item hvr_grow">
                      <i class="fab fa-flickr" title="Flickr"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="https://dribbble.com/xchematic" target="_blank">
                    <div class="scl_item hvr_grow">
                      <i class="fab fa-dribbble" title="Dribbble"></i>
                    </div>
                  </a>
                </td>
                <td>
                  <a href="mailto:team@xchematic.com">

                    <div class="scl_item hvr_grow">
                      <i class="fa fa-envelope" title="Email"></i>
                    </div>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-modal id="footer-video-modal" ref="footer-video-modal" slide-enter size="lg">
      <template v-slot:modal-header="{ close }">
        <div class="d-flex align-items-stretch w-100 justify-content-between">
          <h2>Demo Reel (2012 Classic)</h2>
          <div
              class="close-modal align-self-stretch d-flex align-items-center justify-content-center"
              @click="close()">
            <img src="../assets/img/cross-icon-3-b.png" alt="Close Modal">
          </div>
        </div>
      </template>
      <iframe id="demo_reel_video_frame" width="100%" height="480" frameborder="0"
              allowfullscreen="" mozallowfullscreen="" webkitallowfullscreen=""
              src="https://player.vimeo.com/video/64666315?player_id=demo_reel_vimeo$api=1&amp;title=0&amp;badge=0&amp;byline=0&amp;portrait=0"
              data-ready="true"></iframe>
      <template v-slot:modal-footer>
        <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.xchematic.com/"
           target="_blank" class="share-button">
          <img src="../assets/img/share_f.png" alt="Share on Facebook">
          <p>SHARE ON FACEBOOK</p>
        </a>
        <a href="https://www.twitter.com/share?url=https://www.xchematic.com/&text=Xchematic"
           target="_blank" class="share-button">
          <img src="../assets/img/share_t.png" alt="Share on Twitter">
          <p>SHARE ON TWITTER</p>
        </a>
      </template>
    </b-modal>

    <b-modal id="main-modal" ref="main-modal" size="xl"
             slide-enter
             hide-footer
             scrollable
             static
             header-class="remove-modal-header-padding">
      <template v-slot:modal-header="{ close }">
        <div id="project-modal-header"
             class="d-flex align-items-center w-100 justify-content-end">
          <div
              class="close-modal align-self-stretch d-flex align-items-center justify-content-center"
              @click="close()">
            <i class="fa fa-times"></i>
          </div>
        </div>
      </template>
      <div>
        <div id="store-product-cover-image">
          <img :src="selectedEntry.cover_img" alt="Cover Image" class="parallax-image"
               ref="parallax-image">
        </div>
        <div id="content-box">
          <div v-html="selectedEntry.cover_content"></div>
          <a v-if="selectedEntry.store_link"
             class="print-available"
             :href="selectedEntry.store_link">PRINTS AVAILABLE</a>
          <div class="corner-content text-uppercase">
            <p>{{ selectedEntry.date_place }}</p>
            <p>{{ modalCategoryLabel }}</p>
          </div>

        </div>
        <div id="modal-html-content" v-html="selectedEntry.content"></div>

        <div id="custom-modal-footer"
             class="d-flex flex-column flex-sm-row align-items-center justify-content-center flex-wrap pt-3">
          <a :href="modalShareLinks.facbeook" target="_blank"
             class="share-button mb-2">
            <img src="../assets/img/share_f.png" alt="Share on Facebook">
            <p>SHARE ON FACEBOOK</p>
          </a>
          <a :href="modalShareLinks.twitter" target="_blank"
             class="share-button ml-0 ml-md-2 mb-2">
            <img src="../assets/img/share_t.png" alt="Share on Twitter">
            <p>SHARE ON TWITTER</p>
          </a>
          <a href="#" @click.prevent="scrollModalTop"
             class="share-button ml-0 ml-md-2 mb-2">
            <i class="fa fa-chevron-up" style="color: #fff" title="Facebook"></i>
            <p>BACK TO TOP</p>
          </a>
          <div class="flex-break"></div>
          <a v-if="selectedEntry.store_link"
             class="print-available mb-3"
             :href="selectedEntry.store_link">PRINTS AVAILABLE</a>
        </div>
      </div>
      <!--      <template v-slot:modal-footer>-->
      <!--        -->
      <!--      </template>-->
    </b-modal>
  </b-container>
</template>

<script>
import LazyBackground from "../components/LazyBackground";
import FooterElement from "../components/FooterElement";
import AOS from 'aos';
import axios from 'axios';
import {getCategoryLongName} from "../store";
import countries from '../assets/countries.json';
import Store from "../components/Store";


export default {
  name: "Page",
  components: {Store, FooterElement, LazyBackground},
  data() {
    return {
      contactData: {
        name: '',
        email: '',
        company: '',
        country: null,
        interests: [],
        about: '',
        budget: '',
        currency: '$',
        sending: false,
      },
      loadingIcon: require('../assets/img/rolling_64.svg'),
      selectedEntry: {},
      search: '',
      isMobileSearchVisible: false,
      projectKeys: [
        1, 2
      ],
      contactCountries: countries,
      modalShareLinks: {
        facbeook: '',
        twitter: ''
      },
      aosInterval: 0,
      projectParallaxInstance: null,
      parallaxImageElement: null,
      featuredFooterClients: [
        {
          image: require('../assets/img/redbull-logo.png'),
          background: '#0a2d94',
          isColor: true,
          isProject: false,
          additionalStyles: {
            width: '110px',
          },
        },
        {
          image: require('../assets/img/vmwarelogo.png'),
          background: '#0a2d94',
          isColor: true,
          isProject: false,
          additionalStyles: {},
        },
        {
          image: require('../assets/img/earth-works-footer.png'),
          background: '#000000',
          isColor: true,
          isProject: false,
          additionalStyles: {},
        },
      ],
      featuredFooterProjects: [
        {
          image: require('../assets/img/ika-footer.png'),
          background: require('../assets/img/footer-bg-2.png'),
          isColor: false,
          isProject: true,
          projectURL: 'ika',
        },
        {
          image: require('../assets/img/xs-min-cover-footer.jpg'),
          background: require('../assets/img/footer-bg-2.png'),
          isColor: false,
          isProject: true,
          projectURL: 'xchematic-min',
        },
        {
          image: require('../assets/img/flash-footer.jpg'),
          background: require('../assets/img/footer-bg-2.png'),
          isColor: false,
          isProject: true,
          projectURL: 'flash-forward-interactive-exhibition',
        },
        {
          image: require('../assets/img/XS-BOOST-footer.jpg'),
          background: require('../assets/img/footer-bg-2.png'),
          isColor: false,
          isProject: true,
          projectURL: 'boost'
        },
      ],
      isModalOpen: false,
      flashForwardEntry: null,
      isMobile: false,
    }
  },
  props: {
    openStoreModal: Function,
    storeModal: Object,
    openShoppingCartModal: Function,
  },
  computed: {
    modalCategoryLabel: function () {
      if (!this.selectedEntry) {
        return 'Xchematic'
      }
      switch (this.selectedEntry.category) {
        case 'web':
          return 'Web & Apps';
        case 'branding':
          return 'Design & Branding';
        case 'digital-art':
          return 'Photography & Digital Art';
        case 'film':
          return 'Film & Video';
        case 'blog':
          return 'News & Blog';
        case 'ai':
          return 'Artificial intelligence';
        case 'vr':
          return 'Virtual Reality'
      }
      return 'Xchematic';
    }
  },
  created() {
    window.addEventListener('keyup', this.escapeSearch);
  },
  destroyed() {
    window.removeEventListener('keyup', this.escapeSearch);
  },
  methods: {
    handleWindowResize() {
      this.isMobile = document.body.clientWidth < 768;
    },
    clearSearch() {
      this.search = '';
      this.$store.commit('updateSearch', '');
    },
    escapeSearch(e) {
      if (e.key === 'Escape' && !this.isModalOpen) {
        this.clearSearch();
      }
    },
    resetForm() {
      this.contactData.name = '';
      this.contactData.email = '';
      this.contactData.country = '';
      this.contactData.company = '';
      this.contactData.interests = [];
      this.contactData.budget = '';
      this.contactData.about = '';
      this.contactData.currency = '$';
    },
    isNumber(evt) {
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    generateNewColumnKeys() {
      this.projectKeys = [
        Math.floor(Math.random() * Math.floor(1000)),
        Math.floor(Math.random() * Math.floor(10000))
      ]
    },
    toggleMobileSearchForm() {
      const searchForm = this.$el.querySelector('.search-wrapper');

      if (searchForm.classList.contains('d-none')) {
        this.isMobileSearchVisible = true;
        searchForm.classList.add('fadeIn');
        searchForm.classList.replace('d-none', 'd-block');
      } else {
        this.closeMobileSearchForm();
      }

    },
    closeMobileSearchForm() {
      const searchForm = this.$el.querySelector('.search-wrapper');
      this.isMobileSearchVisible = false;

      function handleAnimationEnd() {
        searchForm.classList.replace('d-block', 'd-none');
        searchForm.classList.remove('fadeOut');
        searchForm.removeEventListener('animationend', handleAnimationEnd);
      }

      if (!searchForm.classList.contains('d-none')) {
        searchForm.classList.replace('fadeIn', 'fadeOut');
        searchForm.addEventListener('animationend', handleAnimationEnd);
      }
    },
    performSearch() {
      document.documentElement.scrollTop = 0;
      this.$store.commit('updateSearch', this.search)
    },
    openFooterVideo() {
      this.$refs['footer-video-modal'].show();
    },
    scrollModalTop() {
      document.querySelector('#main-modal .modal-body').scrollTo({top: 0, behavior: 'smooth'});
    },
    getProjectBySlug(projectSlug) {
      return this.$store.state.allEntries[`home/${projectSlug}`]
    },
    openModal(projectSlug) {
      const data = this.getProjectBySlug(projectSlug);
      if (data) {
        this.selectedEntry = data;
        this.modalShareLinks.facbeook = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
        this.modalShareLinks.twitter = `https://www.twitter.com/share?url=${window.location.href}&text=${document.title}`;
      }
      this.$refs['main-modal'].show();
    },
    hideModal() {
      this.$refs['main-modal'].hide();
    },
    showCategoryPage() {
      if (['contact', 'about'].includes(this.$store.state.category)) {
        this.$router.push({name: this.$store.state.category})
      } else {
        this.$router.push({name: 'page', params: {'category': this.$store.state.category}})
      }
    },
    validEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    sendEmail() {
      this.contactData.sending = true;
      let data = new URLSearchParams();
      data.append('name', this.contactData.name);
      data.append('email', this.contactData.email);
      data.append('company', this.contactData.company);
      data.append('country', this.contactData.country);
      data.append('interests', this.contactData.interests);
      data.append('budget', this.contactData.budget);
      data.append('currency', this.contactData.currency === '$' ? 'USD' : 'EUR');
      data.append('about', this.contactData.about);

      if (!this.validEmail(this.contactData.email)) {
        this.$bvToast.toast('Email is not valid', {
          title: 'Error',
          variant: 'danger',
        });
        this.contactData.sending = false;
        return;
      }

      // Run validations
      if (!this.contactData.name || !this.contactData.email || !this.contactData.company
          || !this.contactData.country || this.contactData.interests.length === 0
          || !this.contactData.about) {
        this.$bvToast.toast('Please fill out the mandatory fields.', {
          title: 'Error',
          variant: 'danger',
        });
        this.contactData.sending = false;
        return;
      }
      axios.get(`/api/send-mail?${data.toString()}`)
          .then(res => {
            if (res.data.success) {
              this.resetForm();
              this.$bvToast.toast('Your information has been sent.', {
                title: 'Success',
                variant: 'success'
              });
            } else {
              this.$bvToast.toast('There was a problem sending the information. Please try again later.', {
                title: 'Error',
                variant: 'danger'
              });
            }
          }).catch(() => {
        this.$bvToast.toast('There was an error!', {
          title: 'Error',
          variant: 'danger'
        });
      }).finally(() => {
        this.contactData.sending = false;
      })
    },
  },
  mounted() {
    this.handleWindowResize()
    window.addEventListener('resize', this.handleWindowResize);
    setTimeout(() => {
      this.flashForwardEntry = this.$store.getters.getEntryByKey('home/flash-forward-interactive-exhibition');
    }, 1000);
    setTimeout(() => {
      AOS.refresh();
    }, 1500);
    const menu = document.querySelector('#collapsible-menu');

    function handleAnimationEnd() {
      menu.classList.remove('delay-1s');
      menu.removeEventListener('animationend', handleAnimationEnd);
    }

    function preventScroll() {
      document.getElementById('main-modal').scrollTop = 0;
    }

    if (!this.$store.state.isCenterMenuVisible) {
      menu.classList.add('animated', 'XslideInDown', 'delay-1s');
      menu.classList.replace('d-none', 'd-block');
      menu.addEventListener('animationend', handleAnimationEnd);
    }
    this.$refs['main-modal'].$on('hide', () => {
      clearInterval(this.aosInterval);
      let parallaxCover = document.querySelector('#main-modal .modal-body')
          .querySelector('#store-product-cover-image .parallax-image');
      parallaxCover.style.transform = `translateY(0) scale(1.15)`;
      if (this.$route.name !== 'store') {
        this.showCategoryPage();
      }
      setTimeout(() => {
        AOS.init()
      }, 100)
    });
    this.$refs['main-modal'].$on('show', () => {
      document.getElementById('main-modal').addEventListener('scroll', preventScroll);
      setTimeout(() => {
        document.getElementById('main-modal').removeEventListener('scroll', preventScroll);
      }, 1000);
    });
    this.$refs['main-modal'].$on('shown', () => {
      let mainModal = document.querySelector('#main-modal .modal-body');
      let parallaxCover = mainModal.querySelector('#store-product-cover-image .parallax-image');
      setTimeout(() => {
        mainModal.addEventListener('scroll', function () {
          let newTranslate = mainModal.scrollTop * 0.2;
          parallaxCover.style.transform = `translateY(${newTranslate}px) scale(1.15)`
        })
      }, 900);

      // Lazy load images
      const lazyLoadedImages = document.querySelectorAll('#modal-html-content .image-wrapper')
      lazyLoadedImages.forEach(el => {
        let imageElement = el.querySelector('img');
        if (imageElement) {
          imageElement.style.display = 'none';
          let tempImage = new Image();
          tempImage.src = imageElement.src;
          tempImage.onload = () => {
            imageElement.style.display = 'inline';
          }
        }
        el.setAttribute('data-aos', 'fade-in');
      });
      AOS.init({
        delay: 300
      });
      this.aosInterval = setInterval(() => {
        AOS.refresh(true);
      }, 500)

    });

    if (this.$route.name === 'page') {
      this.$store.commit('changeCategory', this.$route.params.category);
      this.$store.commit('updateMetaTages', {
        title: `Xchematic - ${getCategoryLongName(this.$route.params.category)}`,
        description: getCategoryLongName(this.$route.params.category)
      });
    }

    this.$root.$on('bv::modal::shown', () => {
      this.isModalOpen = true;
    });

    this.$root.$on('bv::modal::hidden', () => {
      this.isModalOpen = false;
    });

  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (['page', 'about', 'contact'].includes(to.name)) {
        if (from.name === 'store' && ['about', 'contact'].includes(to.name)) {
          vm.$store.commit('setKeepStoreStyles', true);
        } else if (to.name === 'page') {
          vm.$store.commit('setKeepStoreStyles', false);
        }

        // vm.closeMobileSearchForm();
        if (from.name !== 'project') {
          vm.clearSearch();
        }
        if (from.name !== 'project') {
          document.documentElement.scrollTop = 0;
        }
        if (Object.entries(vm.$store.state.allEntries).length === 0) {
          vm.$store.dispatch('loadEntries');
        }
        const chosenCategory = ['about', 'contact'].includes(to.name) ? to.name : to.params.category;
        vm.$store.commit('changeCategory', chosenCategory);
        vm.$store.commit('updateMetaTages', {
          title: `Xchematic - ${getCategoryLongName(chosenCategory)}`,
          description: getCategoryLongName(chosenCategory)
        });
        vm.hideModal();
      } else if (to.name === 'project') { // URL changed to a project route, either from first load or navigated to it
        if (Object.entries(vm.$store.state.allEntries).length === 0) {
          vm.$store.dispatch('loadEntries').then(() => {
            vm.openModal(to.params.project);
            vm.$store.commit('updateMetaTages', {
              title: `Xchematic - ${vm.selectedEntry.title}`,
              description: vm.selectedEntry.short_description,
              image: vm.selectedEntry.cover_img
            });
          });
        } else {
          vm.openModal(to.params.project);
          vm.$store.commit('updateMetaTages', {
            title: `Xchematic - ${vm.selectedEntry.title}`,
            description: vm.selectedEntry.short_description,
            image: vm.selectedEntry.cover_img
          });
        }
      } else if (to.name === 'store') {
        if (!to.params.category) {
          vm.$router.replace({
            name: 'store',
            params: {
              category: 'world-tour'
            }
          })
          return;
        }
        document.documentElement.scrollTop = 0;
        vm.clearSearch();
        vm.$store.commit('updateMetaTages', {
          title: 'Xchematic Art Store',
          description: vm.selectedEntry.short_description,
          image: vm.selectedEntry.cover_img
        });
        if (to.params.category) {
          vm.$store.commit('changeStoreCategory', to.params.category)
        } else if (vm.$store.state.storeData.menu) {
          vm.$store.commit('changeStoreCategory', vm.$store.state.storeData.menu[0].handle);
        }
        if (to.params.product) {
          let selectedProduct;
          if (Object.entries(vm.$store.state.allEntries).length === 0) {
            vm.$store.dispatch('loadStoreProducts').then(() => {
              selectedProduct = vm.$store.getters.getProductByHandle(to.params.product);
              if (selectedProduct) {
                vm.openStoreModal(selectedProduct);
              }
            });
          } else {
            vm.$store.dispatch('loadStoreProducts').then(() => {
              selectedProduct = vm.$store.getters.getProductByHandle(to.params.product);
            });
            if (selectedProduct) {
              vm.openStoreModal(selectedProduct);
            }
          }
        }
      }
    })
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'store') {
      this.$store.commit('changeStoreCategory', to.params.category);
      if (!to.params.product && !from.params.product) {
        // document.documentElement.scrollTop = 0;
        this.clearSearch();
      }
      if (!to.params.product && this.storeModal) { // hide the modal
        this.storeModal.hide();
      }
    } else {
      this.closeMobileSearchForm();
      this.clearSearch();
      this.$store.commit('changeCategory', to.params.category);
      this.$store.commit('updateMetaTages', {
        title: `Xchematic - ${getCategoryLongName(to.params.category)}`,
        description: getCategoryLongName(to.params.category)
      });
      document.documentElement.scrollTop = 0;
      this.generateNewColumnKeys();
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('updateMetaTages', {
      title: `Xchematic`,
      description: 'Web & Apps'
    });
    next();
  }
}
</script>

<style scoped>
.faded-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.projects-column {
  animation-delay: 0.1s;
}

.store-background {
  background-color: #fff;
}

.check_col {
  position: relative;
  display: inline-block;
  width: 49%;
  height: 40px;
  margin-bottom: 15px;
  color: #fff;
  font-size: 12px;
  text-align: left;
}

.store-style .check_col {
  color: #000;
}

@media only screen and (min-width: 992px) {
  .check_col {
    width: 33%;
  }
}

.col_1 {
  position: relative;
  float: left;
  width: 27%;
  height: 100%;
}

.col_2 {
  position: relative;
  float: left;
  display: table;
  width: 66%;
  height: 100%;
}

.col_cell {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  opacity: 0.6;
}

input[type=checkbox]:not(old) {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  font-size: 24px;
  opacity: 0;
}

input[type=checkbox]:not(old) + label {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 42px;
  margin: 0;
  padding: 0;
}

input[type=checkbox]:not(old) + label > span {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #4e4c4d;
  border-radius: 8px;
  background: #121212;
  vertical-align: bottom;
}

input[type=checkbox]:not(old):checked + label > span {
  background: #121212;
}

input[type=checkbox]:not(old):checked + label > span:before {
  content: '✓';
  font-family: "Proxima Nova Rg", Montserrat, "Trebuchet MS", Arial, sans-serif;
  font-weight: 700;
  display: block;
  width: 40px;
  color: #fff;
  font-size: 26px;
  line-height: 42px;
  text-align: center;
}

.body-text {
  font-size: 12px;
}


#mobile-top-row {
  position: fixed;
  z-index: 10;
  width: 100%;
}

.invisible-mobile-top-row {
  height: 56px;
}

#mobile-top-row .mobile-menu-store-link {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  padding: 5px;
  border: 1px solid #aaa;
  font-size: 0.8rem;
  border-radius: 5px;
  z-index: 10;
  opacity: 1;
}

#mobile-top-row .search-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  font-size: 22px;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.5;
}

#mobile-top-row .search-wrapper {
  position: absolute;
}

#mobile-top-row .cart {
  color: #fff;
  font-size: 2rem;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

#mobile-top-row .cart img {
  width: 30px;
}

#mobile-top-row .cart span {
  font-size: 10px;
  position: absolute;
  width: 15px;
  height: 15px;
  top: 3px;
  right: -6px;
  border-radius: 50%;
}

#top-row {
  min-height: 80px;
  width: 100%;
  background-image: url("../assets/img/bg_with_light.png");
  position: fixed;
  z-index: 7;
  top: 0;
  transition: all 1s;
  animation-delay: .3s;
}

#top-row.store-style {
  background-image: none !important;
  background-color: #d0d0d0;
}

.search-form input {
  font-family: "Tw Cen MT", "Trebuchet MS", Arial, sans-serif;
  border-radius: 10px;
  background-color: #121212;
  border: 1px solid #4e4c4d;
  outline: none;
  color: #aaa;
  font-size: 16px;
  padding: 6px 22px 6px 14px;
  height: 45px;
}

.search-form i {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #fff;
}

.search-form.store-style i {
  /*color: #000;*/
}

.search-form.store-style input {
  /*background-color: #bababa;*/
  background-color: #121212;
  border: none;
  /*color: #000000;*/
  color: #fff;
}

@media (max-width: 1200px) {
  .search-form.store-style input {
    color: #fff;
    font-weight: 700;
  }
}

#hidden-center-menu {
  height: 80vh;
  z-index: -1;
}

.full-height-hidden-menu {
  height: 95vh !important;
}

#center-menu {
  background-color: #000;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  font-size: 16px;
  font-family: tw cen mt, trebuchet ms, Arial, sans-serif;
}

#center-menu img {
  width: 90%;
  display: block;
  margin: -50px auto 0 auto;
}

.project-cover {
  width: 99%;
  height: 180px;
  margin: 0.5%;
  margin-bottom: 7px;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
}

.project-cover.highlight {
  height: 370px;
}

.project-cover.secondary {
  width: 49%;
  margin: 0.5%;
}

.project-cover .thumbnail-cover {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.column-footer {
  height: 180px;
  width: 50%;
  background-color: #000;
  /*position: absolute;*/
  /*left: 0;*/
  /*bottom: 0;*/
  display: flex;
}

.column-footer .footer-element {
  height: 100%;
  width: 50%;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.column-footer .footer-element::before {
  content: '';
  position: absolute;
  transform: rotate(-45deg);
  background-color: #000;
  z-index: 10;
  width: 60px;
  height: 60px;
  top: -30px;
  left: -30px;
  padding-left: 15px;
  padding-top: 36px;
}

.column-footer .footer-element .footer-title {
  font-family: tw cen mt, trebuchet ms, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-align: left;
  margin-top: 10px;
  margin-left: 35px;
}

#footer-video {
  background-image: url("../assets/img/footer-bg-3.png");
  position: relative;
}

#footer-video .video-bg {
  width: 210px;
  height: 120px;
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;
}

#footer-video .play-icon {
  position: absolute;
  width: 53px;
  height: 53px;
  left: 50%;
  top: 60%;
  margin-left: -26px;
  margin-top: -26px;
  cursor: pointer;
  transition: all .5s;
}

#footer-video .play-icon:hover {
  transform: scale(1.2);
}

#footer-social-links {
  font-family: tw cen mt, trebuchet ms, Arial, sans-serif;
  color: #fff;
  background-image: url("../assets/img/footer-bg-4.png");
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social_tbl .scl_item {
  transition: all .5s;
}

.scl_item:hover {
  transform: scale(1.15);
}

#footer-social-links a:hover {
  text-decoration: none;
}

#footer-social-links .footer-title {
  align-self: start;
  margin-left: 35px;
}

.social_tbl td {
  width: 50px;
  height: 44px;
}

.social_tbl tr {
  width: auto;
}

.social_tbl .scl_item {
  width: 36px;
  height: 36px;
  background-image: url("../assets/img/icon_00.png");
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a9a4aa;
}

/* Contact Page Styles */

.content-title {
  font-size: 20px;
  font-weight: 700;
}

.content-title a {
  width: 100%;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.store-style .content-title a {
  color: #000;
}

.content-title a img {
  margin-left: 10px;
}

.content-subtitle {
  font-size: 16px;
  font-weight: 700;
}

#contact-form {
  font-family: tw cen mt, trebuchet ms, Arial, sans-serif;
}

#contact-form input,
#contact-form select,
#contact-form textarea {
  /*background-color: #070707;*/
  background-color: #121212;
  /*color: #fff;*/
  color: #6c757d;
  border-radius: 5px;
  outline: none;
  border: 1px solid #4e4c4d;
  font-size: 14px;
  margin: 5px 12px;
  text-transform: uppercase;
}

#contact-form .submit-btn {
  padding: 6px 12px;
  color: #595959;
  border: 1px solid #4e4c4d;
  background-color: #000;
  border-radius: 8px;
}

#contact-form .submit-btn:hover {
  background-color: #121212;
}

#contact-form textarea {
  background-color: #121212;
  min-height: 120px;
}

#contact-form .contact-checkbox {
  font-family: proxima nova rg, Montserrat, trebuchet ms, Arial, sans-serif;
  font-size: 10px;
  color: #fff;
}

.contact-social-icons .social_tbl a:hover {
  text-decoration: none;
}


/* About Page Styles */

.accolade-image {
  width: 50px;
}

.award-link {
  color: #fff;
  text-decoration: none;
}

.store-style .award-link {
  color: #000;
}

.social-media-hover {
  opacity: 0.8 !important;
}

.social-media-hover:hover {
  opacity: 1 !important;
}

@media (max-width: 768px) {
  .column-footer {
    height: 350px;
    flex-direction: column;
  }

  .column-footer .footer-element {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .column-footer {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .column-footer .footer-element .footer-title {
    font-size: 11px;
  }
}

.about-bullet-points {
  font-size: 12px;
  margin-bottom: 0;
  /*font-family: "Open Serif Book", Montserrat, "Trebuchet MS", Arial, sans-serif;*/
}

.about-bullet-points a {
  color: #fff;
  text-decoration: underline;
}

.store-style .about-bullet-points a {
  color: #000;
}

.about-bullet-points a.no-underline {
  text-decoration: none;
}

.about-bullet-points a.no-underline:hover {
  text-decoration: underline;
}

.bullet-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}


</style>
